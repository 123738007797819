@import "https://fonts.googleapis.com/css2?family=Exo:wght@400;500;600;700&display=swap";
.hide-small {
  display: none;
}

@media screen and (width >= 900px) {
  .hide-small {
    display: block;
  }
}

.hide-large {
  display: block;
}

@media screen and (width >= 900px) {
  .hide-large {
    display: none;
  }
}

#skip-to-content {
  z-index: 999;
  color: #000;
  background-color: #fff;
  border-radius: 4px;
  padding: 32px;
  font-size: 2rem;
  transition: top .25s;
  position: fixed;
  top: -25vh;
  left: 50%;
  transform: translateX(-50%);
}

#skip-to-content:focus-visible {
  top: 24px;
}

@media print {
  #skip-to-content {
    display: none !important;
  }
}

.button {
  text-align: center;
  letter-spacing: .02em;
  text-transform: uppercase;
  color: #fff;
  box-shadow: none;
  background: #64af50;
  border: none;
  border-radius: 10px;
  min-width: 230px;
  max-width: 100%;
  margin-top: 1.5rem;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  transition: all .2s ease-in-out;
  display: inline-block;
}

@media (width <= 720px) {
  .button {
    min-width: 230px;
    padding: 15px 30px;
  }
}

.button:hover {
  color: #fff;
  background: #347848;
}

.button:visited {
  color: #fff;
}

.button.small {
  min-width: initial;
  width: auto;
  padding: 10px 33px;
  font-size: 13px;
  line-height: 15px;
}

.button.full-width {
  min-width: initial;
  width: 100%;
}

.button.Grey {
  background: #808f83;
}

.button.Grey:hover {
  background: #2a312c;
}

.chevron {
  width: .45em;
  height: .45em;
  top: 50%;
  right: -1rem;
  transform: translateY(-50%);
}

.chevron:before {
  content: "";
  vertical-align: top;
  border-style: solid;
  border-width: .15em .15em 0 0;
  width: .45em;
  height: .45em;
  display: inline-block;
  position: relative;
  top: .3em;
  right: 0;
  transform: rotate(45deg);
}

.wave-wrapper {
  z-index: 5;
  width: 100%;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
}

.wave-wrapper .cls-1 {
  fill: #fff;
}

.wave-wrapper .wave-container {
  margin: 0 -1px;
  position: relative;
}

.wave-wrapper .wave-container svg {
  position: absolute;
  bottom: 0;
}

.wave-wrapper.wave-top {
  top: 0;
  bottom: inherit;
  margin: -1px;
}

.wave-wrapper.wave-top .wave-container {
  transform: scale(-1);
}

.page-content .umbraco-forms-form .umbraco-forms-container {
  flex-wrap: wrap;
  margin: 0 -1rem;
  display: flex;
}

@media (width <= 1113px) {
  .page-content .umbraco-forms-form .umbraco-forms-container {
    margin: 0;
  }
}

.page-content .umbraco-forms-form .shortanswer {
  flex: calc(50% - 2rem);
  margin: 0 1rem;
}

@media (width <= 1113px) {
  .page-content .umbraco-forms-form .shortanswer {
    flex: 100%;
    margin: 0;
  }
}

.page-content .umbraco-forms-form .longanswer {
  flex: calc(100% - 2rem);
  margin: 0 1rem;
}

@media (width <= 1113px) {
  .page-content .umbraco-forms-form .longanswer {
    flex: 100%;
    margin: 0;
  }
}

.page-content .umbraco-forms-form fieldset {
  margin: 0;
  padding: 0;
}

.page-content .umbraco-forms-form label {
  color: #235030;
  font-size: 16px;
  font-weight: bold;
}

.page-content .umbraco-forms-form input.text, .page-content .umbraco-forms-form textarea {
  width: 100%;
  padding: .7rem;
  display: block;
  max-width: 100% !important;
}

.page-content .umbraco-forms-form .umbraco-forms-field-wrapper {
  margin: .5rem 0 1rem;
}

.page-content .umbraco-forms-form input.text, .page-content .umbraco-forms-form input.title, .page-content .umbraco-forms-form textarea, .page-content .umbraco-forms-form select {
  color: #716568;
  background-color: #0000;
  border: 1px solid #465149;
  border-radius: 7px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1em;
}

.page-content .umbraco-forms-form input.text:focus, .page-content .umbraco-forms-form input.title:focus, .page-content .umbraco-forms-form textarea:focus, .page-content .umbraco-forms-form select:focus {
  border: 1px solid #c9c9c9;
}

.page-content .umbraco-forms-form textarea {
  height: 145px;
}

.page-content .umbraco-forms-form button, .page-content .umbraco-forms-form input[type="button"], .page-content .umbraco-forms-form input[type="reset"], .page-content .umbraco-forms-form input[type="submit"] {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .02em;
  background: #64af50;
  border: none;
  border-radius: 7px;
  min-width: 214px;
  max-width: 100%;
  margin-top: 0;
  padding: 10px 4rem;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  display: inline-block;
  position: relative;
}

@media (width <= 720px) {
  .page-content .umbraco-forms-form button, .page-content .umbraco-forms-form input[type="button"], .page-content .umbraco-forms-form input[type="reset"], .page-content .umbraco-forms-form input[type="submit"] {
    width: 100%;
    padding: 10px;
  }
}

.page-content .umbraco-forms-form button:hover, .page-content .umbraco-forms-form input[type="button"]:hover, .page-content .umbraco-forms-form input[type="reset"]:hover, .page-content .umbraco-forms-form input[type="submit"]:hover {
  box-shadow: 0 4px 16px #0000001f;
}

.page-content .umbraco-forms-form .umbraco-forms-indicator {
  float: left;
}

.page-content .umbraco-forms-form input.contourError, .page-content .umbraco-forms-form textarea.contourError, .page-content .umbraco-forms-form input.input-validation-error, .page-content .umbraco-forms-form textarea.input-validation-error {
  background: none;
}

.page-content .umbraco-forms-form span.contourError, .page-content .umbraco-forms-form span.field-validation-error {
  color: #64af50 !important;
  background: none !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 #fff !important;
}

input:-webkit-autofill:hover {
  -webkit-box-shadow: inset 0 0 #fff !important;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: inset 0 0 #fff !important;
}

input:-webkit-autofill:active {
  -webkit-box-shadow: inset 0 0 #fff !important;
}

.page-content .form-container {
  text-align: left;
  max-width: 472px;
}

.page-content .form-container label {
  color: #235030;
  font-size: 16px;
  font-weight: bold;
}

.page-content .form-container input, .page-content .form-container textarea {
  width: 100%;
  padding: .7rem;
  display: block;
  max-width: 100% !important;
}

.page-content .form-container input, .page-content .form-container textarea, .page-content .form-container select {
  color: #716568;
  background-color: #0000;
  border: 1px solid #465149;
  border-radius: 7px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1em;
}

.page-content .form-container input:focus, .page-content .form-container textarea:focus, .page-content .form-container select:focus {
  border: 1px solid #c9c9c9;
}

.page-content .form-container input[type="checkbox"] {
  width: auto;
  margin-top: 6px;
  margin-right: 8px;
}

.page-content .form-container .field {
  margin-bottom: 1rem;
}

.page-content .form-container .hs-form-required {
  color: #64af50;
}

.page-content .form-container .hs-field-desc {
  color: #767676;
  margin-bottom: 1rem;
  font-size: 14px;
  font-style: normal;
  line-height: 1.3em;
}

.page-content .form-container select {
  text-align: left;
  width: 100%;
  padding: .75rem .5rem;
}

.page-content .form-container textarea {
  height: 145px;
}

.page-content .form-container ul {
  padding: 0;
  list-style: none;
}

.page-content .form-container ul span {
  margin: 0;
}

.page-content .form-container button, .page-content .form-container input[type="button"], .page-content .form-container input[type="reset"], .page-content .form-container input[type="submit"] {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .02em;
  background: #64af50;
  border: none;
  border-radius: 7px;
  min-width: 214px;
  max-width: 100%;
  margin-top: 1rem;
  padding: 1rem 4rem;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  display: inline-block;
  position: relative;
}

@media (width <= 720px) {
  .page-content .form-container button, .page-content .form-container input[type="button"], .page-content .form-container input[type="reset"], .page-content .form-container input[type="submit"] {
    width: 100%;
    padding: 10px;
  }
}

.page-content .form-container button:hover, .page-content .form-container input[type="button"]:hover, .page-content .form-container input[type="reset"]:hover, .page-content .form-container input[type="submit"]:hover {
  box-shadow: 0 4px 16px #0000001f;
}

.popup-form {
  z-index: 9;
  background: #fbfbfb;
  border-radius: 7px;
  width: 100%;
  max-width: 554px;
  max-height: calc(90vh - 118px);
  padding: 3rem 2rem;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow-x: auto;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 20px 5px #0000001a;
}

.popup-form.active {
  display: block;
}

@media (width <= 720px) {
  .popup-form {
    max-width: 95%;
    margin-top: 1rem;
    padding: 2rem 1rem;
    top: 80px;
    bottom: 1rem;
    transform: translate(-50%);
  }
}

.popup-form .close {
  content: "";
  opacity: .6;
  text-align: center;
  background: url("../close-x.b5750e65.svg") center no-repeat;
  width: 25px;
  height: 25px;
  font-weight: bold;
  line-height: 25px;
  display: none;
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.popup-form .close:hover {
  opacity: 1;
  cursor: pointer;
}

@media (width <= 720px) {
  .popup-form .close {
    display: block;
  }
}

.popup-form label.hs-error-msg {
  color: #e64;
  font-size: 14px;
  font-weight: normal;
}

.popup-form .text {
  color: #606d63;
}

.popup-form .form-container {
  color: #606d63;
  width: 100%;
  margin: 0 auto;
}

* {
  box-sizing: border-box;
}

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
  display: block;
}

audio, canvas, video {
  zoom: 1;
  display: inline;
}

audio:not([controls]), [hidden] {
  display: none;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  scroll-behavior: smooth;
  font-size: 100%;
  overflow-y: scroll;
}

body {
  margin: 0;
  font-size: 1em;
  line-height: 1.4;
}

body, button, input, select, textarea {
  font-family: sans-serif;
}

::selection {
  color: #fff;
  text-shadow: none;
  background: #64af50;
}

a {
  color: #64af50;
}

a.loc-phonenumber.microsite, a.loc-link.microsite {
  color: #d7323c;
}

a:visited, a:hover {
  color: #64af50;
}

a:hover, a:active {
  outline: 0;
}

b, strong {
  font-weight: bold;
}

blockquote {
  margin: 1em 40px;
}

dfn {
  font-style: italic;
}

hr {
  border: 0;
  border-top: 1px solid #ccc;
  height: 1px;
  margin: 1em 0;
  padding: 0;
  display: block;
}

ins {
  color: #000;
  background: #ff9;
  text-decoration: none;
}

mark {
  color: #000;
  background: #ff0;
  font-style: italic;
  font-weight: bold;
}

pre, code, kbd, samp {
  _font-family: "courier new", monospace;
  font-family: monospace;
  font-size: 1em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: none;
}

q:before, q:after {
  content: "";
  content: none;
}

small {
  font-size: 85%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

p, dl, hr, h1, h2, h3, h4, h5, h6, ol, ul, pre, table, address, fieldset {
  margin-bottom: 10px;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  line-height: 1.3em;
}

h1, h2.big {
  letter-spacing: .02em;
  text-transform: uppercase;
  font-size: 42px;
  font-style: normal;
  font-weight: bold;
  line-height: 56px;
}

@media (width <= 900px) {
  h1, h2.big {
    font-size: 30px;
    line-height: 42px;
  }
}

h2 {
  letter-spacing: .02em;
  text-transform: uppercase;
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 40px;
}

@media (width <= 900px) {
  h2 {
    font-size: 24px;
    line-height: 32px;
  }
}

h3 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
}

h4 {
  font-size: 18px;
}

ul, ol {
  margin: 0 0 1em;
  padding: 0 0 0 40px;
}

dd {
  margin: 0 0 0 40px;
}

nav ul, nav ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  -ms-interpolation-mode: bicubic;
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure, form {
  margin: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

label {
  cursor: pointer;
  display: block;
}

legend {
  border: 0;
  margin-left: -7px;
  padding: 0;
}

button, input, select, textarea {
  vertical-align: baseline;
  vertical-align: middle;
  margin: 0;
  font-size: 100%;
}

button, input {
  line-height: normal;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
  overflow: visible;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  vertical-align: top;
  resize: vertical;
  overflow: auto;
}

input:invalid, textarea:invalid {
  background-color: #f0dddd;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.clearfix:before, .clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

.mapContainer {
  width: 100%;
  max-width: 1300px;
  height: 600px;
  margin: 0 auto;
}

.mapboxgl-popup-content {
  border-radius: 10px !important;
  box-shadow: 0 0 10px #0003 !important;
}

.mapboxgl-popup-content .loc-phonenumber {
  margin-bottom: 10px;
  display: block;
}

.mapboxgl-ctrl-attrib {
  display: none !important;
}

.marker {
  background: #64af50;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  position: relative;
  box-shadow: 0 0 5px #0006;
}

.marker.microsite {
  background-color: #d7323c;
}

.marker.microsite h4 {
  color: #d7323c;
}

.marker h4 {
  white-space: nowrap;
  color: #64af50;
  width: auto;
  font-size: 1rem;
  display: block;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
}

.cookie-information {
  color: #fff;
  opacity: 0;
  z-index: 7;
  -o-transition: all ease-out .8s;
  background: #0006;
  width: 100%;
  max-width: 340px;
  padding: 40px;
  transition: all .8s ease-out;
  position: fixed;
  bottom: -140px;
  left: 20px;
}

.cookie-information.show {
  opacity: 1;
  bottom: 20px;
}

.cookie-information .cookie-information-text {
  color: #fff;
}

.cookie-information a {
  color: #fff;
  margin-top: 5px;
  display: inline-block;
}

.cookie-information .accept-cookies {
  color: #eee;
  cursor: pointer;
  text-align: center;
  background: #64af50;
  width: 35px;
  height: 35px;
  font-size: 20px;
  line-height: 35px;
  position: absolute;
  top: 0;
  right: 0;
}

.hero-block-wrapper {
  position: relative;
  overflow: hidden;
}

.hero-block-wrapper .overlay {
  z-index: 2;
  background: linear-gradient(89.7deg, #0009 .25%, #0000 40.63%), linear-gradient(177.09deg, #ffffffba -.77%, #fff0 40.46%);
  position: absolute;
  inset: 0;
}

@media (width <= 900px) {
  .hero-block-wrapper .overlay {
    background: linear-gradient(89.7deg, #00000080 -38.75%, #0000 109.63%), linear-gradient(177.09deg, #ffffffba 6.23%, #fff0 40.46%);
  }
}

.hero-block-wrapper .button-container {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
  margin-top: 1rem;
  display: grid;
}

.hero-block-wrapper .button-container .button {
  min-width: 0;
  margin-top: 0;
  padding: 15px 20px;
}

@media (width >= 900px) {
  .hero-block-wrapper .button-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.hero-block-wrapper .hero-slider {
  height: 846px;
  position: relative;
  overflow: hidden;
}

@media (width <= 900px) {
  .hero-block-wrapper .hero-slider {
    height: 650px;
  }
}

.hero-block-wrapper .hero-slider .slide-item {
  height: 846px;
  position: relative;
}

@media (width <= 900px) {
  .hero-block-wrapper .hero-slider .slide-item {
    height: 650px;
  }
}

.hero-block-wrapper .hero-slider .slide-item .slide-text-container {
  z-index: 9;
  width: 100%;
  padding-left: 15%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

@media (width <= 1700px) {
  .hero-block-wrapper .hero-slider .slide-item .slide-text-container {
    padding-left: 8%;
  }
}

@media (width <= 1455px) {
  .hero-block-wrapper .hero-slider .slide-item .slide-text-container {
    padding-left: 34px;
  }
}

@media (width <= 720px) {
  .hero-block-wrapper .hero-slider .slide-item .slide-text-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.hero-block-wrapper .hero-slider .slide-item .slide-text-container .container {
  flex-direction: column;
  justify-content: center;
  max-width: 457px;
  height: 100%;
  display: flex;
}

.hero-block-wrapper .hero-slider .slide-item img, .hero-block-wrapper .hero-slider .slide-item video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.hero-block-wrapper .hero-content {
  z-index: 8;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.hero-block-wrapper .hero-content .inner {
  align-items: center;
  height: 100%;
  display: flex;
}

.hero-block-wrapper .hero-content .inner .content {
  color: #fff;
  width: 100%;
  max-width: 457px;
}

@media (width <= 900px) {
  .hero-block-wrapper .hero-content .inner .content {
    padding-top: 80px;
  }
}

.hero-block-wrapper .hero-content .inner .content .title {
  color: #fff;
  text-transform: uppercase;
  margin-top: 0;
  font-size: 42px;
  font-weight: bold;
  line-height: 56px;
}

@media (width <= 900px) {
  .hero-block-wrapper .hero-content .inner .content .title {
    font-size: 26px;
    line-height: 120%;
  }
}

.hero-block-wrapper .hero-content .inner .content .text {
  margin-bottom: 1rem;
  font-size: 18px;
  font-weight: normal;
  line-height: 27px;
}

@media (width <= 900px) {
  .hero-block-wrapper .hero-content .inner .content .text {
    font-size: 18px;
    line-height: 23px;
  }
}

.hero-block-wrapper .hero-content .inner .content .button {
  width: 262px;
  padding: 15px;
}

.hero-block-wrapper .hero-slider .slick-arrow {
  -webkit-appearance: none;
  text-indent: -9999px;
  z-index: 5;
  opacity: .72;
  background: #fff;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 50px;
  transition: all .2s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0 0 #d3d3d3;
}

.hero-block-wrapper .hero-slider .slick-arrow:hover {
  opacity: 1;
}

.hero-block-wrapper .hero-slider .slick-arrow:after {
  content: "";
  border-top: 3px solid #1b1b1b;
  border-right: 3px solid #1b1b1b;
  width: 16px;
  height: 16px;
  display: block;
  position: absolute;
}

.hero-block-wrapper .hero-slider .slick-arrow:focus {
  outline: none;
}

.banner-block-wrapper {
  justify-content: center;
  align-items: center;
  min-height: 848px;
  display: flex;
  position: relative;
}

.banner-block-wrapper .overlay {
  z-index: 2;
  background: linear-gradient(180.06deg, #000000a6 104.94%, #0000 106.56%);
  position: absolute;
  inset: 0;
}

.banner-block-wrapper .background-image {
  object-fit: cover;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
}

.banner-block-wrapper .banner-content {
  width: 100%;
  height: 100%;
  padding: 4rem 0;
}

.banner-block-wrapper .banner-content .inner {
  z-index: 3;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  position: relative;
}

.banner-block-wrapper .banner-content .content {
  color: #fbfbfb;
  border-radius: 5px;
  width: 100%;
  max-width: 862px;
  margin: 0 auto;
  font-size: 18px;
  line-height: 29px;
}

.banner-block-wrapper .banner-content .content .text, .banner-block-wrapper .banner-content .content .button-container {
  max-width: 525px;
  margin: 0 auto;
}

.banner-block-wrapper .banner-content .content .button-centered {
  text-align: center;
}

@media (width <= 720px) {
  .banner-block-wrapper .banner-content .content {
    font-size: 16px;
    left: 1rem;
  }
}

.banner-block-wrapper .banner-content .content .icon-list {
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 3rem 0 1rem;
  display: flex;
}

.banner-block-wrapper .banner-content .content .icon-list .icon {
  text-align: center;
  flex: calc(25% - 2rem);
  margin: 1rem;
}

@media (width <= 900px) {
  .banner-block-wrapper .banner-content .content .icon-list .icon {
    text-align: center;
    flex: 0 calc(50% - 2rem);
  }
}

.banner-block-wrapper .banner-content .content .icon-list .icon:hover img {
  top: -.5rem;
}

.banner-block-wrapper .banner-content .content .icon-list .icon img {
  object-fit: contain;
  width: auto;
  max-width: 100%;
  height: 93px;
  transition: top .15s ease-in-out;
  position: relative;
  top: 0;
}

.banner-block-wrapper .banner-content .content .icon-list .icon h4 {
  color: #68be81;
  letter-spacing: .01em;
  text-transform: uppercase;
  margin-top: 1rem;
  font-size: 16px;
  line-height: 18px;
}

.box-block-wrapper {
  padding: 1rem 0;
}

.box-block-wrapper .box-list {
  flex-wrap: wrap;
  justify-content: space-evenly;
  display: flex;
}

.box-block-wrapper .box-list .box {
  text-align: center;
  letter-spacing: .02em;
  background: #64af50;
  border-radius: 10px;
  flex: 0 0 300px;
  margin: 1rem 0;
  padding: 3rem 1rem 6rem;
  font-size: 16px;
  font-weight: normal;
  line-height: 130%;
  transition: all .3s ease-in-out;
  position: relative;
}

.box-block-wrapper .box-list .box:hover {
  box-shadow: 0 0 20px 5px #0003;
}

.box-block-wrapper .box-list .box .image {
  height: 80px;
}

.box-block-wrapper .box-list .box .image img {
  width: auto;
  height: 80px;
}

.box-block-wrapper .box-list .box .text {
  max-width: 200px;
  margin: 3rem auto 0;
}

.box-block-wrapper .box-list .box .bottom {
  position: absolute;
  bottom: 2rem;
  left: 1rem;
  right: 1rem;
}

.box-block-wrapper .box-list .box .button {
  color: #64af50;
  text-transform: uppercase;
  letter-spacing: .02em;
  background: #fbfbfb;
  padding: 13px 57px;
  font-size: 13px;
  font-weight: bold;
  line-height: 15px;
}

.box-block-wrapper .box-list .box .button:hover {
  color: #fff;
  background: #347848;
}

.contact-block-wrapper {
  padding: 2rem 0;
}

.contact-block-wrapper .title {
  text-transform: initial;
  margin-bottom: 3rem;
  font-size: 26px;
  line-height: 34px;
}

.contact-block-wrapper .container {
  width: 100%;
  margin: 0 auto;
}

.contact-block-wrapper .container.width-1 {
  max-width: 750px;
}

.contact-block-wrapper .container.width-2 {
  max-width: 997px;
}

.contact-block-wrapper .container.width-3 {
  max-width: 100%;
}

.contact-block-wrapper .coworker-list {
  flex-wrap: wrap;
  margin: 0 -1rem;
  display: flex;
}

.contact-block-wrapper .coworker-list .coworker {
  flex: 1 0 calc(33.33% - 2rem);
  max-width: calc(50% - 2rem);
  margin: 0 1rem 1rem;
  font-size: 14px;
  line-height: 126.24%;
}

.contact-block-wrapper .coworker-list .coworker._4 {
  flex: 1 0 calc(33.33% - 1rem);
}

.contact-block-wrapper .coworker-list .coworker._5:nth-last-child(2), .contact-block-wrapper .coworker-list .coworker._8:nth-last-child(2), .contact-block-wrapper .coworker-list .coworker._11:nth-last-child(2), .contact-block-wrapper .coworker-list .coworker._14:nth-last-child(2) {
  max-width: calc(33.33% - 2rem);
}

.contact-block-wrapper .coworker-list .coworker .image img {
  object-fit: cover;
  border-radius: 7px;
  width: auto;
  height: 290px;
}

@media (width <= 720px) {
  .contact-block-wrapper .coworker-list .coworker .image img {
    height: 218px;
  }
}

.contact-block-wrapper .coworker-list .coworker h4 {
  color: #64af50;
  margin-top: .5rem;
  font-size: 14px;
}

.contact-block-wrapper .coworker-list .coworker p {
  margin: 0 0 .5rem;
}

.contact-block-wrapper .coworker-list .coworker a {
  color: #606d63;
}

.contact-block-wrapper .coworker-list .coworker a:hover {
  color: #64af50;
}

.contact-block-wrapper .coworker-list .coworker .info img {
  width: 30px;
}

.contact-block-wrapper .column-list {
  letter-spacing: .02em;
  background: #ebefec;
  margin-top: 2rem;
  padding: 0 2rem;
  font-size: 14px;
  line-height: 126.24%;
}

@media (width <= 720px) {
  .contact-block-wrapper .column-list {
    padding: 1rem;
  }
}

.contact-block-wrapper .column-list .columns {
  flex-wrap: nowrap;
}

@media (width <= 720px) {
  .contact-block-wrapper .column-list .columns {
    flex-wrap: wrap;
  }
}

.contact-block-wrapper .umbraco-form .umbraco-forms-form {
  padding-top: 0;
}

.contact-block-wrapper .umbraco-form .umbraco-forms-form .umbraco-forms-field {
  flex: calc(100% - 2rem);
  margin: 0 1rem;
}

@media (width <= 1113px) {
  .contact-block-wrapper .umbraco-form .umbraco-forms-form .umbraco-forms-field {
    flex: 100%;
    margin: 0;
  }
}

.contact-block-wrapper .umbraco-form .umbraco-forms-form .umbraco-forms-field.mandatory .umbraco-forms-indicator {
  color: #64af50;
  float: none;
}

.contact-block-wrapper .umbraco-form .umbraco-forms-form .help-block {
  font-size: 1rem;
  font-style: italic;
  line-height: 1;
}

.contact-block-wrapper .umbraco-form .umbraco-forms-form .dataconsent input[type="checkbox"] {
  margin-right: 4px;
}

.contact-block-wrapper .umbraco-form .umbraco-forms-form .dataconsent input, .contact-block-wrapper .umbraco-form .umbraco-forms-form .dataconsent label {
  vertical-align: top;
  display: inline;
}

.contact-block-wrapper .umbraco-form .umbraco-forms-form .richtext .umbraco-forms-field-wrapper {
  margin: 0;
}

.contact-block-wrapper .umbraco-form .umbraco-forms-form .richtext .umbraco-forms-field-wrapper p:first-child {
  margin-top: .5rem;
  margin-bottom: 0;
}

.contact-container {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.contact-container .card {
  background-color: #fff;
  border-radius: 15px;
  flex-direction: column;
  width: 600px;
  margin: 100px 0;
  padding: 20px;
  display: flex;
  box-shadow: 0 0 10px #0000004d;
}

@media only screen and (width <= 600px) {
  .contact-container .card {
    align-items: center;
    width: 90%;
    margin: 10px 10px 50px;
  }
}

.contact-container .card .top {
  width: 100px;
  margin-bottom: 15px;
}

.contact-container .card .content {
  flex-direction: row;
  display: flex;
}

@media only screen and (width <= 600px) {
  .contact-container .card .content {
    flex-direction: column;
  }
}

.contact-container .card .content img {
  object-fit: cover;
  border-radius: 10px;
  width: 250px;
  height: 350px;
}

.contact-container .card .content .info {
  margin-left: 30px;
}

.contact-container .card .content .info img {
  width: 50px;
  height: 50px;
}

@media only screen and (width <= 600px) {
  .contact-container .card .content .info {
    margin-top: 10px;
    margin-left: 0;
  }
}

.text-image-block-wrapper {
  padding: 1rem 0;
  position: relative;
}

.text-image-block-wrapper .reverse-order .text-column {
  order: 2;
  justify-content: flex-end;
}

.text-image-block-wrapper .reverse-order .image-column {
  order: 1;
}

.text-image-block-wrapper.regular .reverse-order .image-column {
  justify-content: flex-start;
}

.text-image-block-wrapper.regular .column {
  align-items: center;
  display: flex;
}

.text-image-block-wrapper.regular .content {
  max-width: 450px;
}

@media (width <= 900px) {
  .text-image-block-wrapper.regular .content {
    text-align: center;
    max-width: 100%;
  }

  .text-image-block-wrapper.regular .text-column {
    order: 2;
  }

  .text-image-block-wrapper.regular .image-column {
    order: 1;
  }
}

.text-image-block-wrapper.regular .image-column {
  justify-content: flex-end;
}

@media (width <= 900px) {
  .text-image-block-wrapper.regular .image-column {
    justify-content: center;
  }
}

.text-image-block-wrapper.regular .image-column .image {
  border-radius: 50%;
  width: 525px;
  height: 525px;
  overflow: hidden;
}

@media (width <= 1113px) {
  .text-image-block-wrapper.regular .image-column .image {
    width: 425px;
    height: 425px;
  }
}

@media (width <= 900px) {
  .text-image-block-wrapper.regular .image-column .image {
    width: 325px;
    height: 325px;
  }
}

.text-image-block-wrapper.regular .image-column .image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.text-image-block-wrapper.alt-design .column {
  align-items: center;
  display: flex;
}

.text-image-block-wrapper.alt-design .content {
  max-width: 525px;
  padding-right: 2rem;
}

.text-image-block-wrapper.alt-design .image-column {
  justify-content: flex-end;
  min-height: 605px;
}

.text-image-block-wrapper.alt-design .image-column .image {
  border-top-left-radius: 300px;
  border-bottom-left-radius: 300px;
  width: 50vw;
  height: 605px;
  position: absolute;
  top: 50%;
  right: 0;
  overflow: hidden;
  transform: translateY(-50%);
}

.text-image-block-wrapper.alt-design .image-column .image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.text-image-block-wrapper.alt-design .reverse-order .text-column {
  justify-content: right;
}

.text-image-block-wrapper.alt-design .reverse-order .text-column .content {
  padding-left: 2rem;
}

.text-image-block-wrapper.alt-design .reverse-order .image-column .image {
  right: inherit;
  border-radius: 0 300px 300px 0;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media (width <= 900px) {
  .text-image-block-wrapper.alt-design .inner {
    padding: 0;
  }

  .text-image-block-wrapper.alt-design .column {
    position: relative;
  }

  .text-image-block-wrapper.alt-design .text-column {
    order: 2;
    padding: 3rem 1rem 0 !important;
  }

  .text-image-block-wrapper.alt-design .text-column .content {
    text-align: center;
    max-width: 100%;
    padding: 0 !important;
  }

  .text-image-block-wrapper.alt-design .image-column {
    order: 1;
    justify-content: center;
    min-height: 305px;
  }

  .text-image-block-wrapper.alt-design .image-column .image {
    width: calc(100vw - 2rem);
    height: 305px;
  }
}

.text-block-wrapper {
  padding: 2rem 0;
}

.text-block-wrapper.margin-1 {
  margin: 2rem 0;
  padding: 2rem 0;
}

.text-block-wrapper.margin-2 {
  margin: 4rem 0;
  padding: 6rem 0;
}

.text-block-wrapper.margin-3 {
  margin: 4rem 0;
  padding: 8rem 0;
}

.text-block-wrapper .content .text.width-1 {
  max-width: 750px;
}

.text-block-wrapper .content .text.width-2 {
  max-width: 997px;
}

.text-block-wrapper .content .text.width-3 {
  max-width: 100%;
}

.text-block-wrapper.centered .text {
  margin: 0 auto;
}

.text-block-wrapper.centered .button-container {
  text-align: center;
}

.text-block-wrapper.centered .columns {
  justify-content: space-evenly;
  margin-top: 2rem;
}

.icon-block-wrapper {
  padding: 4rem 0;
}

.icon-block-wrapper .column {
  align-items: center;
  display: flex;
  overflow: hidden;
  padding: 0 !important;
}

.icon-block-wrapper .text-column {
  justify-content: right;
}

.icon-block-wrapper .content {
  max-width: 450px;
  padding: 2rem 0;
}

@media (width <= 900px) {
  .icon-block-wrapper .content {
    text-align: center;
    max-width: 100%;
  }

  .icon-block-wrapper .text-column {
    order: 1;
  }

  .icon-block-wrapper .icon-column {
    order: 2;
  }
}

.icon-block-wrapper .icon-list {
  flex-wrap: wrap;
  margin: 0 -1rem;
  padding: 2rem 0;
  display: flex;
}

.icon-block-wrapper .icon-list.full {
  margin: -33px -2px;
  overflow: hidden;
}

.icon-block-wrapper .icon-list.full .icon {
  border-bottom: 1px solid #808f83;
  border-right: 1px solid #808f83;
  flex: 0 20%;
  justify-content: center;
  align-items: center;
  height: 250px;
  margin: 0;
  padding: 1rem;
  display: flex;
}

@media (width <= 1113px) {
  .icon-block-wrapper .icon-list.full .icon {
    flex: 0 25%;
  }
}

@media (width <= 900px) {
  .icon-block-wrapper .icon-list.full .icon {
    flex: 0 33.33%;
  }
}

@media (width <= 720px) {
  .icon-block-wrapper .icon-list.full .icon {
    flex: 0 50%;
    height: 200px;
  }
}

.icon-block-wrapper .icon-list .icon {
  flex: 0 calc(33% - 2rem);
  margin: 0 1rem;
  padding: 1rem;
}

@media (width <= 900px) {
  .icon-block-wrapper .icon-list .icon {
    text-align: center;
    flex: 0 calc(50% - 2rem);
  }
}

.icon-block-wrapper .icon-list .icon a:hover {
  opacity: .8;
}

.icon-block-wrapper .icon-list .icon img {
  object-fit: contain;
  width: auto;
  max-width: 100%;
  height: 100px;
}

.article-block-wrapper .column-4 {
  flex-grow: initial !important;
}

.article-block-wrapper .column-3 {
  margin: 2rem 1rem !important;
}

@media (width <= 900px) {
  .article-block-wrapper .column-3 {
    margin: 1rem 0 !important;
  }
}

.article-block-wrapper .main-title {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.article-block-wrapper .filter-title {
  text-transform: initial;
  text-align: center;
  margin-bottom: 0;
  font-size: 25.92px;
  line-height: 34px;
}

.article-block-wrapper .filter {
  text-align: center;
  padding: 0 0 1rem;
}

.article-block-wrapper .filter .button {
  background: #808f83;
  margin: .5rem;
}

.article-block-wrapper .filter .button.mixitup-control-active {
  background: #2a312c;
}

.article-block-wrapper .article-list {
  flex-wrap: wrap;
  padding: 2rem 0;
  overflow: hidden;
}

.article-block-wrapper .article-list .item {
  border-radius: 7px;
  height: 100%;
  margin-bottom: 2rem;
  padding-bottom: 6rem;
  transition: all .3s ease-in-out;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 20px 5px #0000001a;
}

.article-block-wrapper .article-list .item:hover {
  box-shadow: 0 0 20px 5px #0003;
}

.article-block-wrapper .article-list .item:hover .bottom a .chevron {
  right: -2rem;
}

.article-block-wrapper .article-list .image {
  background: #7c7c7c;
  height: 317px;
  margin-bottom: 1rem;
  overflow: hidden;
}

.article-block-wrapper .article-list .image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.article-block-wrapper .article-list .content {
  padding: 1rem 2rem 2rem;
}

.article-block-wrapper .article-list .content .title {
  color: #235030;
  font-size: 19px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
}

.article-block-wrapper .article-list .content .text {
  letter-spacing: .02em;
  color: #606d63;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 126.24%;
}

.article-block-wrapper .article-list .content .date, .article-block-wrapper .article-list .content .article-type {
  color: #64af50;
  letter-spacing: .01em;
  margin-bottom: 1.5rem;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
}

.article-block-wrapper .article-list .content .article-type {
  text-transform: uppercase;
}

.article-block-wrapper .article-list .content .bottom {
  position: absolute;
  bottom: 4rem;
  left: 2rem;
  right: 2rem;
}

.article-block-wrapper .article-list .content .bottom a {
  color: #64af50;
  letter-spacing: .02em;
  text-transform: uppercase;
  padding-bottom: .5rem;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 16px;
  display: inline-block;
  position: relative;
}

.article-block-wrapper .article-list .content .bottom a:after {
  content: "";
  background: #64af50;
  height: 2px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.article-block-wrapper .article-list .content .bottom a .chevron {
  transform: inherit;
  margin-left: .5rem;
  transition: all .3s ease-in-out;
  position: absolute;
  top: 0;
}

.article-block-wrapper .article-list .content .bottom a .chevron:before {
  width: .5rem;
  height: .5rem;
}

.article-block-wrapper .article-list .content.blue .date, .article-block-wrapper .article-list .content.blue .bottom a {
  color: #0091b9;
}

.article-block-wrapper .article-list .content.blue .bottom a:after {
  background: #0091b9;
}

.article-block-wrapper .article-list.latest-articles.three {
  flex-wrap: nowrap;
  padding: 2rem 0;
}

@media (width <= 1113px) {
  .article-block-wrapper .article-list.latest-articles.three {
    flex-wrap: wrap;
  }
}

.article-block-wrapper .article-list.latest-articles.three .column-4:first-child {
  flex: 0 calc(43.33% - 2rem);
}

@media (width <= 1113px) {
  .article-block-wrapper .article-list.latest-articles.three .column-4:first-child {
    flex: 0 calc(33.33% - 2rem);
  }
}

@media (width <= 900px) {
  .article-block-wrapper .article-list.latest-articles.three .column-4:first-child {
    flex: 100%;
    margin: 0;
  }
}

.article-block-wrapper .article-list.latest-articles.three .item {
  margin-bottom: 4rem;
  padding-bottom: 4rem;
}

.article-block-wrapper .article-list.latest-articles.three .item .bottom {
  bottom: 2rem;
}

.reference-block-wrapper {
  padding: 2rem 0;
}

.reference-block-wrapper .filter-title {
  text-transform: initial;
  text-align: center;
  font-size: 25.92px;
  line-height: 34px;
}

.reference-block-wrapper .filter {
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 4rem;
}

.reference-block-wrapper .filter .button {
  background: #808f83;
  margin: .5rem;
}

.reference-block-wrapper .filter .button.mixitup-control-active {
  background: #2a312c;
}

.reference-block-wrapper .reference-list {
  flex-wrap: wrap;
}

.reference-block-wrapper .reference-list .column {
  max-width: calc(50% - 2rem);
}

@media (width <= 900px) {
  .reference-block-wrapper .reference-list .column {
    max-width: 100%;
  }
}

.reference-block-wrapper .reference-list .item-right .item .image-container {
  border-radius: 0 300px 300px 0;
}

@media (width <= 900px) {
  .reference-block-wrapper .reference-list .item-right .item .image-container {
    border-radius: 50%;
  }

  .reference-block-wrapper .reference-list .item-right .item .image {
    position: inherit;
  }
}

.reference-block-wrapper .reference-list .item-right .item .excerpt-container {
  justify-content: left;
}

.reference-block-wrapper .reference-list .item-right .item .excerpt-container .excerpt, .reference-block-wrapper .reference-list .item-right .item .content {
  text-align: left;
}

@media (width <= 900px) {
  .reference-block-wrapper .reference-list .item-right .item .content {
    text-align: center;
  }
}

.reference-block-wrapper .reference-list .item-right .item .content .keywords .word .divider:last-child, .reference-block-wrapper .reference-list .item-right .item .content .keywords .word:last-child .divider:last-child {
  display: none;
}

.reference-block-wrapper .reference-list .item:hover .excerpt-container {
  display: flex;
}

.reference-block-wrapper .reference-list .item .image-container {
  border-top-left-radius: 300px;
  border-bottom-left-radius: 300px;
  width: 100%;
  height: 472px;
  position: relative;
  overflow: hidden;
}

@media (width <= 900px) {
  .reference-block-wrapper .reference-list .item .image-container {
    border-radius: 50%;
    width: 280px;
    height: 280px;
    margin: 0 auto;
  }
}

.reference-block-wrapper .reference-list .item .image {
  height: 100%;
  transition: box-shadow .2s ease-in-out;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

@media (width <= 900px) {
  .reference-block-wrapper .reference-list .item .image {
    position: inherit;
  }
}

.reference-block-wrapper .reference-list .item .image:hover {
  box-shadow: 0 0 20px 5px #0000001a;
}

.reference-block-wrapper .reference-list .item .image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.reference-block-wrapper .reference-list .item .excerpt-container {
  color: #fff;
  background: #00000080;
  justify-content: right;
  align-items: center;
  display: none;
  position: absolute;
  inset: 0;
}

.reference-block-wrapper .reference-list .item .excerpt-container .excerpt {
  text-align: right;
  max-width: 70%;
  padding: 0 2rem;
}

@media (width <= 900px) {
  .reference-block-wrapper .reference-list .item .excerpt-container {
    display: none !important;
  }
}

.reference-block-wrapper .reference-list .item .excerpt-container-mobile {
  max-width: 500px;
  margin: 0 auto;
  padding: 1rem 0;
  display: none;
}

@media (width <= 900px) {
  .reference-block-wrapper .reference-list .item .excerpt-container-mobile {
    display: block;
  }
}

.reference-block-wrapper .reference-list .item .content {
  text-align: right;
  padding: 1rem 0;
}

@media (width <= 900px) {
  .reference-block-wrapper .reference-list .item .content {
    text-align: center;
  }
}

.reference-block-wrapper .reference-list .item .content h3 {
  margin-bottom: .5rem;
  font-size: 19px;
}

.reference-block-wrapper .reference-list .item .content .keywords {
  letter-spacing: .02em;
  text-transform: uppercase;
  color: #808f83;
  font-size: 16px;
  line-height: 18px;
}

.reference-block-wrapper .reference-list .item .content .keywords .word .divider {
  margin: 0 .5rem;
}

.reference-block-wrapper .reference-list .item .content .keywords .word .divider:last-child, .reference-block-wrapper .reference-list .item .content .keywords .word:first-child .divider:first-child {
  display: none;
}

.reference-block-wrapper .latest-references {
  flex-wrap: wrap;
  padding: 2rem 0;
}

.reference-block-wrapper .latest-references .item {
  border-radius: 7px;
  height: 100%;
  margin-bottom: 2rem;
  padding-bottom: 6rem;
  transition: all .3s ease-in-out;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 20px 5px #0000001a;
}

.reference-block-wrapper .latest-references .item:hover {
  box-shadow: 0 0 20px 5px #0003;
}

.reference-block-wrapper .latest-references .item:hover .bottom a .chevron {
  margin-left: 1.5rem;
}

.reference-block-wrapper .latest-references .image {
  background: #7c7c7c;
  height: 317px;
  margin-bottom: 1rem;
  overflow: hidden;
}

.reference-block-wrapper .latest-references .image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.reference-block-wrapper .latest-references .content {
  padding: 1rem 2rem 2rem;
}

.reference-block-wrapper .latest-references .content .keywords {
  letter-spacing: .01em;
  text-transform: uppercase;
  color: #68be81;
  margin-bottom: .25rem;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
}

.reference-block-wrapper .latest-references .content .keywords .word .divider {
  margin: 0 .5rem;
}

.reference-block-wrapper .latest-references .content .keywords .word:last-child .divider {
  display: none;
}

.reference-block-wrapper .latest-references .content .title {
  color: #235030;
  font-size: 19px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
}

.reference-block-wrapper .latest-references .content .text {
  letter-spacing: .02em;
  color: #606d63;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 126.24%;
}

.reference-block-wrapper .latest-references .content .date {
  color: #64af50;
  letter-spacing: .01em;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
}

.reference-block-wrapper .latest-references .content .bottom {
  position: absolute;
  bottom: 4rem;
  left: 2rem;
  right: 2rem;
}

.reference-block-wrapper .latest-references .content .bottom a {
  color: #64af50;
  letter-spacing: .02em;
  text-transform: uppercase;
  padding-bottom: .5rem;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 16px;
  display: inline-block;
  position: relative;
}

.reference-block-wrapper .latest-references .content .bottom a:after {
  content: "";
  background: #64af50;
  height: 2px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.reference-block-wrapper .latest-references .content .bottom a .chevron {
  top: inherit;
  left: inherit;
  transform: inherit;
  margin-left: .5rem;
  transition: all .3s ease-in-out;
  position: absolute;
}

.reference-block-wrapper .latest-references .content .bottom a .chevron:before {
  width: .5rem;
  height: .5rem;
}

.map-block-wrapper {
  padding-bottom: 4rem;
}

.map-block-wrapper .map-container {
  height: 435px;
  position: relative;
}

.map-block-wrapper .map-container iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.site-header {
  z-index: 20;
  height: 118px;
  transition: background .3s ease-in;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.site-header.scrolled {
  background: #fff;
}

.site-header.scrolled .logo.white {
  display: none;
}

.site-header.scrolled .logo.black {
  display: block;
}

.site-header .logo {
  max-width: 169px;
}

.site-header .logo.black {
  display: none;
}

.site-header.background {
  background: #fbfbfb;
}

.site-header.background .logo.white {
  display: none;
}

.site-header.background .logo.black {
  display: block;
}

.site-header .inner {
  height: 100%;
  padding: 1rem 2rem;
  display: flex;
}

.site-header .logo {
  flex: 0 337px;
  align-items: center;
  display: flex;
}

@media (width <= 720px) {
  .site-header .logo {
    flex: 0 110px;
    max-width: 110px;
  }
}

.site-header .main-navigation {
  text-align: right;
  flex: auto;
}

.site-header .main-navigation ul {
  list-style: none;
}

.site-header .main-navigation li {
  margin-left: 2rem;
  display: inline-block;
}

.site-header .main-navigation li .open-sub {
  width: 25px;
  height: 25px;
  display: inline-block;
}

.site-header .main-navigation li .open-sub:before {
  content: "";
  vertical-align: top;
  border: .15em solid #000;
  border-width: .15em .15em 0 0;
  width: .35em;
  height: .35em;
  display: inline-block;
  position: relative;
  top: 8px;
  right: 10px;
  transform: rotate(135deg);
}

.site-header .main-navigation li .open-sub:hover {
  cursor: pointer;
}

.site-header .main-navigation li.open .open-sub:before {
  top: 11px;
  transform: rotate(-45deg);
}

.site-header .main-navigation li.open .mega-menu, .site-header .main-navigation li.open .sub-menu {
  display: block;
}

.site-header .main-navigation li a, .site-header .main-navigation li .no-link {
  color: #000;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
}

.site-header .main-navigation li a:hover, .site-header .main-navigation li .no-link:hover {
  opacity: .8;
  cursor: pointer;
  text-decoration: underline;
}

.site-header .main-navigation li.selected > a {
  color: #64af50;
}

.site-header .main-navigation li.selected .open-sub:before {
  border-color: #64af50;
}

.site-header .main-navigation li.highlight a {
  color: #0091b9;
}

.site-header .main-navigation li.highlight .open-sub:before {
  border-color: #0091b9;
}

.site-header .main-navigation .top-menu {
  margin-top: .5rem;
  margin-bottom: 1rem;
}

.site-header .main-navigation .top-menu li a {
  letter-spacing: .03em;
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
}

.site-header .main-navigation .main-menu {
  margin-top: .5rem;
}

.site-header .main-navigation .main-menu li {
  height: 40px;
}

.site-header .main-navigation .main-menu li a {
  letter-spacing: .01em;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
}

.site-header .main-navigation .main-menu li .arrow {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.site-header .main-navigation .main-menu li .arrow img {
  height: 10px;
}

.site-header .main-navigation .main-menu .sub-menu {
  text-align: left;
  background: #fbfbfb;
  border-radius: 0 0 7px 7px;
  padding: 1rem;
  display: none;
  position: absolute;
  top: 118px;
  right: -.5rem;
}

.site-header .main-navigation .main-menu .sub-menu li {
  height: auto;
  margin: 0;
  padding: 1rem .5rem;
  display: block;
}

.site-header .main-navigation .main-menu .sub-menu li.selected > a {
  color: #26d07c;
}

.site-header .main-navigation .main-menu .sub-menu li a {
  letter-spacing: .02em;
  color: #465149;
  text-transform: inherit;
  justify-content: left;
  width: 225px;
  font-size: 14px;
  font-weight: normal;
  line-height: 15px;
}

.site-header .main-navigation .mega-menu {
  text-align: left;
  background: #fbfbfb;
  border-radius: 0 0 7px 7px;
  width: 100%;
  max-width: 1100px;
  max-height: calc(95vh - 118px);
  padding: 1rem 1rem 3rem;
  display: none;
  position: absolute;
  top: 118px;
  right: 0;
  overflow-y: auto;
}

.site-header .main-navigation .mega-menu .columns-container {
  display: flex;
}

.site-header .main-navigation .mega-menu .column {
  flex: auto;
  width: 100%;
  padding: 1rem;
}

.site-header .main-navigation .mega-menu .column .header {
  border-bottom: 1px solid #64af50;
  max-width: 266px;
  margin-bottom: .5rem;
  padding-bottom: .5rem;
  font-weight: bold;
}

.site-header .main-navigation .mega-menu .column a, .site-header .main-navigation .mega-menu .column .no-link {
  letter-spacing: .02em;
  color: #465149;
  text-transform: inherit;
  padding: .5rem 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 15px;
  display: block;
}

.site-header .main-navigation .mega-menu .column li {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
}

.site-header .main-navigation .mega-menu .column li.selected > a {
  color: #64af50;
}

.site-header .main-navigation .mega-menu .column li.has-children {
  padding-left: 1.25rem;
  position: relative;
}

.site-header .main-navigation .mega-menu .column li.has-children .column-sub-menu {
  display: none;
}

.site-header .main-navigation .mega-menu .column li.has-children.active {
  display: block;
}

.site-header .main-navigation .mega-menu .column li.has-children.active .expander {
  background-image: url("../close.4c9dd8d7.png");
}

.site-header .main-navigation .mega-menu .column li.has-children.active .column-sub-menu {
  display: block;
}

.site-header .main-navigation .mega-menu .column li.has-children .expander {
  content: "";
  background-image: url("../open.4fb723f0.png");
  background-position: center;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  display: block;
  position: absolute;
  top: .5rem;
  left: 0;
}

.site-header .main-navigation .mega-menu .column li.has-children .expander:hover {
  cursor: pointer;
}

.site-header .main-navigation .language-picker {
  padding-left: 1rem;
}

.site-header .main-navigation .language-picker .lang-link {
  border-left: 1px solid #000;
  padding: 0 .35rem 0 .5rem;
  font-weight: normal;
  display: inline-block;
}

.site-header .main-navigation .language-picker .lang-link:first-child {
  border-left: none;
}

.site-header .main-navigation .language-picker .lang-link.current-culture {
  color: #64af50;
}

.site-header .nav-icon {
  display: none;
}

@media (width <= 900px) {
  .site-header {
    height: 80px;
    position: fixed;
  }

  .site-header.active, .site-header.scrolled {
    background: #fbfbfb;
  }

  .site-header.active .logo.white, .site-header.scrolled .logo.white {
    display: none;
  }

  .site-header.active .logo.black, .site-header.scrolled .logo.black {
    display: block;
  }

  .site-header .inner {
    padding: 1rem;
  }

  .site-header .main-navigation {
    z-index: 9998;
    text-align: left;
    background: #fbfbfb;
    width: 100%;
    height: calc(100vh - 80px);
    padding-top: 1rem;
    padding-bottom: 4rem;
    transition: right .3s ease-in-out;
    display: block;
    position: fixed;
    top: 80px;
    right: -100%;
    overflow-y: auto;
  }

  .site-header .main-navigation.active {
    right: 0;
  }

  .site-header .main-navigation .main-menu, .site-header .main-navigation .top-menu {
    margin: 0;
  }

  .site-header .main-navigation .main-menu li a, .site-header .main-navigation .top-menu li a {
    padding: 1rem;
    font-weight: bold;
  }

  .site-header .main-navigation .main-menu {
    overflow: initial;
    margin-top: 0;
  }

  .site-header .main-navigation .main-menu li .open-sub {
    top: 29px;
    right: 1rem;
  }

  .site-header .main-navigation .main-menu li .open-sub:before {
    top: 8px;
    right: -10px;
  }

  .site-header .main-navigation .main-menu li.open .open-sub:before {
    top: 11px;
  }

  .site-header .main-navigation .main-menu li.open .mega-menu, .site-header .main-navigation .main-menu li.open .sub-menu {
    display: block;
  }

  .site-header .main-navigation .main-menu .mega-menu {
    position: inherit;
    top: inherit;
    right: inherit;
    overflow-y: inherit;
    max-height: 100%;
    padding: 0 0 0 1rem;
  }

  .site-header .main-navigation .main-menu .mega-menu .columns-container {
    display: block;
  }

  .site-header .main-navigation .main-menu .mega-menu .column {
    margin-top: 0;
    padding: .5rem 0;
  }

  .site-header .main-navigation .main-menu .mega-menu .column li.has-children .expander {
    top: 13px;
  }

  .site-header .main-navigation .main-menu .sub-menu {
    position: inherit;
    top: inherit;
    right: inherit;
    padding: 0;
    display: none;
  }

  .site-header .main-navigation .main-menu .sub-menu li {
    padding: .5rem 0;
  }

  .site-header .main-navigation .main-menu .sub-menu li a {
    padding: .5rem 1rem;
  }

  .site-header .main-navigation ul {
    width: 100%;
    padding: 0;
    overflow: hidden;
  }

  .site-header .main-navigation li {
    border-bottom: 1px solid #fff3;
    width: 100%;
    margin-left: 0;
    padding: 0;
    display: block;
    height: auto !important;
  }

  .site-header .main-navigation li a, .site-header .main-navigation li .no-link {
    text-transform: uppercase;
    background-color: #0000;
    width: calc(100% - 50px);
    padding: 1.25rem 1.5rem;
    display: inline-block;
    position: relative;
    font-size: 14px !important;
    line-height: 25px !important;
  }

  .site-header .language-picker {
    margin-top: 2rem;
  }

  .site-header .language-picker .lang-link {
    width: auto;
  }

  .site-header .nav-icon {
    width: 24px;
    display: block;
    position: absolute;
    top: 25px;
    right: 1.5rem;
  }

  .site-header .nav-icon:hover {
    cursor: pointer;
  }

  .site-header .nav-icon:after, .site-header .nav-icon:before, .site-header .nav-icon div {
    content: "";
    background-color: #000;
    height: 2px;
    margin: 6px 0;
    transition: all .2s ease-in-out;
    display: block;
  }

  .site-header .nav-icon.active:before {
    transform: translateY(8px)rotate(135deg);
  }

  .site-header .nav-icon.active:after {
    transform: translateY(-8px)rotate(-135deg);
  }

  .site-header .nav-icon.active div {
    transform: scale(0);
  }
}

body {
  color: #606d63;
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 27px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Exo, sans-serif;
}

.page-content {
  min-height: 50vh;
  overflow-x: hidden;
}

img {
  max-width: 100%;
}

p, ol, ul, pre, table, address, fieldset {
  margin-bottom: 1rem;
}

h1, h2, h3, h4, h5, h6 {
  color: #000;
  margin-top: 0;
  margin-bottom: 1rem;
}

a {
  text-decoration: none;
}

a:hover, a:focus-visible {
  color: #26d07c;
  text-decoration: none;
}

:focus {
  outline: none;
}

.inner {
  max-width: 1440px;
  margin: 0 auto;
  padding: 2rem;
}

@media (width <= 720px) {
  .inner {
    padding: 1rem;
  }
}

.inner .columns {
  margin: 0 -1rem;
  display: flex;
}

@media (width <= 900px) {
  .inner .columns {
    flex-wrap: wrap;
    margin: 0;
  }
}

.inner .columns .column {
  flex: calc(50% - 2rem);
  margin: 0 1rem;
  padding: 1rem 0;
}

@media (width <= 900px) {
  .inner .columns .column {
    flex: 100%;
    margin: 0;
  }
}

.inner .columns .column-12 {
  flex: 100%;
  margin: 0 1rem;
  padding: .5rem 0;
}

.inner .columns .column-3 {
  word-break: break-word;
  flex: 0 calc(25% - 2rem);
  margin: 0 1rem;
  padding: .5rem 0;
}

@media (width <= 1440px) {
  .inner .columns .column-3 {
    flex: 0 calc(50% - 2rem);
  }
}

@media (width <= 720px) {
  .inner .columns .column-3 {
    flex: 100%;
    margin: 0;
  }
}

.inner .columns .column-4 {
  flex: 0 calc(33.33% - 2rem);
  margin: 0 1rem;
  padding: .5rem 0;
}

@media (width <= 900px) {
  .inner .columns .column-4 {
    flex: 100%;
    margin: 0;
  }
}

.inner .columns .column-8 {
  flex: 1 0 calc(66.66% - 2rem);
  margin: 0 1rem;
  padding: .5rem 0;
}

@media (width <= 900px) {
  .inner .columns .column-8 {
    flex: 100%;
    margin: 0;
  }
}

.inner .columns .column-9 {
  flex: calc(75% - 2rem);
  margin: 0 1rem;
  padding: .5rem 0;
}

@media (width <= 900px) {
  .inner .columns .column-9 {
    flex: 100%;
    margin: 0;
  }
}

.white-text, .white-text h1, .white-text h2, .white-text h3, .white-text h4, .white-text h5, .white-text h6 {
  color: #fbfbfb;
}

.green-text {
  color: #68be81 !important;
}

.light-green-text {
  color: #9dd1ac !important;
}

.grey-text {
  color: #888 !important;
}

.breadcrumbsContainer {
  max-width: 1440px;
  margin: 20px auto -10px;
  padding-left: 2rem;
}

.breadcrumbsContainer .breadcrumbs {
  z-index: 10;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.breadcrumbsContainer .breadcrumbs p {
  margin: 5px 0;
}

.breadcrumbsContainer .breadcrumbs a {
  color: #000;
  text-decoration: underline;
}

.breadcrumbsContainer .breadcrumbs .active {
  font-weight: bold;
  text-decoration: none;
}

.site-footer {
  color: #fff;
  letter-spacing: .02em;
  background: #64af50;
  font-size: 14px;
  line-height: 126.24%;
  position: relative;
}

.site-footer .inner {
  padding-top: 10rem;
  padding-bottom: 4rem;
}

@media (width <= 900px) {
  .site-footer .inner {
    padding-top: 4rem;
  }
}

.site-footer h2 {
  color: #fff;
  margin-bottom: 3rem;
  font-size: 19px;
  font-weight: bold;
  line-height: 24px;
}

@media (width <= 900px) {
  .site-footer h2 {
    margin-bottom: 2rem;
  }
}

.site-footer h4 {
  color: #fff;
  letter-spacing: .01em;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
}

@media (width <= 900px) {
  .site-footer .column {
    text-align: center;
  }
}

.site-footer .content {
  height: 100%;
  display: flex;
}

@media (width <= 900px) {
  .site-footer .content {
    text-align: center;
    flex-wrap: wrap;
  }
}

.site-footer .content .offices {
  flex: auto;
  display: flex;
}

@media (width <= 900px) {
  .site-footer .content .offices {
    flex-wrap: wrap;
  }
}

.site-footer .content .offices .office {
  flex: 1 1 0;
  padding-right: 1rem;
}

@media (width <= 900px) {
  .site-footer .content .offices .office {
    flex: 100%;
    margin: 1rem 0;
    padding-right: 0;
  }
}

.site-footer .content .links {
  flex: 0 130px;
}

@media (width <= 900px) {
  .site-footer .content .links {
    flex: auto;
  }
}

.site-footer .content .links a {
  color: #fff;
  margin: 1rem 0;
  display: block;
}

.site-footer .content .links a:hover {
  text-decoration: underline;
}

.site-footer .content .social {
  text-align: right;
  flex: auto;
  align-self: flex-end;
}

@media (width <= 900px) {
  .site-footer .content .social {
    text-align: center;
    margin: 1rem 0;
  }
}

.site-footer .content .social a {
  align-self: flex-end;
  display: inline-block;
}

.site-footer .content .social a img {
  margin: 1rem 1.5rem;
  transition: all .15s ease-in-out;
  display: inline-block;
  top: 0;
}

.site-footer .content .social a:hover img {
  position: relative;
  top: -4px;
}

.site-footer .content .logo {
  text-align: right;
  flex: 224px;
  align-self: flex-end;
}

@media (width <= 900px) {
  .site-footer .content .logo {
    text-align: center;
  }
}

.site-footer .content .logo img {
  filter: brightness(0) invert();
  margin: 1rem 0;
}

.start-page {
  background: #fff url("../logo.cdc1c688.png");
}

.article-hero-wrapper {
  height: 746px;
  position: relative;
  overflow: hidden;
}

@media (width <= 900px) {
  .article-hero-wrapper {
    height: 524px;
  }
}

.article-hero-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.article-hero-wrapper .overlay {
  z-index: 2;
  background: linear-gradient(89.7deg, #0009 .25%, #0000 40.63%), linear-gradient(177.09deg, #ffffffba -.77%, #fff0 40.46%);
  position: absolute;
  inset: 0;
}

.article-hero-wrapper .date {
  color: #50afe1;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}

.article-hero-wrapper .hero-content {
  z-index: 4;
  z-index: 3;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.article-hero-wrapper .hero-content .inner {
  align-items: center;
  height: 100%;
  display: flex;
}

.article-hero-wrapper .hero-content .inner .content {
  color: #fff;
  width: 100%;
  max-width: 457px;
}

@media (width <= 900px) {
  .article-hero-wrapper .hero-content .inner .content {
    padding-top: 80px;
  }
}

.article-hero-wrapper .hero-content .inner .content .title {
  color: #fff;
  text-transform: uppercase;
  margin-top: 0;
  font-size: 42px;
  font-weight: bold;
  line-height: 56px;
}

@media (width <= 900px) {
  .article-hero-wrapper .hero-content .inner .content .title {
    font-size: 26px;
    line-height: 120%;
  }
}

.article-hero-wrapper .hero-content .inner .content .text {
  margin-bottom: 1rem;
  font-size: 18px;
  font-weight: normal;
  line-height: 27px;
}

@media (width <= 900px) {
  .article-hero-wrapper .hero-content .inner .content .text {
    font-size: 18px;
    line-height: 23px;
  }
}

.article-hero-wrapper .hero-content .inner .content .button {
  width: 262px;
  padding: 15px;
}

.search-page-wrapper {
  padding: 4rem 0;
}

.search-page-wrapper form {
  border-bottom: 1px solid #c4c4c4;
  justify-content: center;
  align-items: center;
  max-width: 614px;
  height: 60px;
  margin: 2rem auto;
  padding: 1rem 0;
  font-family: sofia-pro, sans-serif;
  display: flex;
}

.search-page-wrapper form button.search-button {
  appearance: none;
  background: none;
  border: none;
  height: 100%;
  padding: 0;
}

.search-page-wrapper form .arrow {
  background-image: url("../arrow-right.3ab2a9f4.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 23px;
  height: 23px;
  transition: right .2s ease-in-out;
  display: block;
  position: absolute;
  top: 50%;
  right: 33px;
  transform: translateY(-50%);
}

.search-page-wrapper form input.search-input {
  box-shadow: none;
  box-sizing: border-box;
  color: #555;
  background: none;
  border: none;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  font-size: 32px;
  font-weight: normal;
  line-height: 120%;
}

.search-page-wrapper form:placeholder {
  color: #909090;
}

.search-page-wrapper .no-result {
  color: #000;
  text-align: center;
  margin-bottom: .5rem;
  font-size: 22px;
  font-weight: normal;
}

.search-page-wrapper .result-wrapper {
  max-width: 720px;
  min-height: 600px;
  margin: 0 auto;
  padding: 4rem 0;
}

.search-page-wrapper .result-wrapper .result {
  border-bottom: 1px solid #bbb;
  padding: 2rem 0 1rem;
}

.search-page-wrapper .result-wrapper .result .title {
  margin-bottom: .5rem;
  font-size: 18px;
  font-weight: normal;
}

.search-page-wrapper .result-wrapper .result .text {
  max-width: 800px;
}

input[type="search"]::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}

input[type="search"]::-ms-reveal {
  width: 0;
  height: 0;
  display: none;
}

input[type="search"]::-webkit-search-decoration {
  display: none;
}

input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

input[type="search"]::-webkit-search-results-button {
  display: none;
}

input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

@keyframes splide-loading {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.splide__track--draggable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.splide__track--fade > .splide__list > .splide__slide {
  opacity: 0;
  z-index: 0;
  margin: 0 !important;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  height: 100%;
  display: flex;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  pointer-events: none;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
}

.splide__pagination li {
  pointer-events: auto;
  margin: 0;
  line-height: 1;
  list-style-type: none;
  display: inline-block;
}

.splide:not(.is-overflow) .splide__pagination {
  display: none;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  visibility: hidden;
  position: relative;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
  margin: 0;
  position: relative;
  list-style-type: none !important;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  contain: strict;
  border: 2px solid #999;
  border-left-color: #0000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: auto;
  animation: 1s linear infinite splide-loading;
  display: inline-block;
  position: absolute;
  inset: 0;
}

.splide__sr {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.splide__toggle.is-active .splide__toggle__play, .splide__toggle__pause {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  z-index: 0;
  position: relative;
  overflow: hidden;
}

body.micro-site {
  flex-flow: column;
  justify-content: space-between;
  min-height: 100vh;
  display: flex;
}

body.micro-site #content {
  flex-grow: 1;
}

body.micro-site .button {
  text-transform: none;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  min-width: 160px;
  margin: 0;
  padding: 16px 32px;
  font-family: Exo, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  transition: color .25s, background-color .25s;
}

body.micro-site .button:focus-visible {
  outline-offset: 1px;
  outline: 2px solid #d7323c;
}

@media screen and (width >= 900px) {
  body.micro-site .button {
    padding: 16px 48px;
  }
}

body.micro-site .button--outline-white {
  color: #fff;
  background-color: #0000;
  border: 1px solid #fff;
}

body.micro-site .button--outline-white:hover, body.micro-site .button--outline-white:focus-visible {
  color: #000;
  background-color: #fff;
}

body.micro-site .button--fill-red {
  color: #fff;
  background-color: #d7323c;
  border: 1px solid #d7323c;
}

body.micro-site .button--fill-red:hover, body.micro-site .button--fill-red:focus-visible {
  color: #d7323c;
  background-color: #fff;
}

body.micro-site .button--outline-red {
  color: #d7323c;
  background-color: #0000;
  border: 1px solid #d7323c;
}

body.micro-site .button--outline-red:hover, body.micro-site .button--outline-red:focus-visible {
  color: #fff;
  background-color: #d7323c;
}

body.micro-site .button--outline-black {
  color: #000;
  background-color: #0000;
  border: 1px solid #000;
}

body.micro-site .button--outline-black:hover, body.micro-site .button--outline-black:focus-visible {
  color: #fff;
  background-color: #000;
}

body.micro-site .button--small {
  padding: 10px 16px;
}

@media screen and (width >= 900px) {
  body.micro-site .button--small {
    padding: 10px 24px;
  }
}

body.micro-site .button--macro {
  margin-top: 16px;
}

body.micro-site .grid {
  flex-flow: wrap;
  justify-content: space-between;
  margin: 8px -8px;
  display: flex;
}

@media screen and (width >= 900px) {
  body.micro-site .grid {
    margin: 16px -16px;
  }
}

body.micro-site .grid .grid-card {
  padding: 8px;
  display: inline-block;
}

body.micro-site .grid .grid-card:focus-visible {
  outline-offset: 1px;
  outline: 2px solid #d7323c;
}

@media screen and (width >= 900px) {
  body.micro-site .grid .grid-card {
    padding: 16px;
  }
}

body.micro-site .grid .grid-card--square {
  aspect-ratio: 1;
}

body.micro-site .grid .grid-card--3 {
  width: 100%;
  height: 100%;
}

@media screen and (width >= 720px) {
  body.micro-site .grid .grid-card--3 {
    width: 50%;
  }
}

@media screen and (width >= 900px) {
  body.micro-site .grid .grid-card--3 {
    width: 33.3333%;
  }
}

body.micro-site .grid .grid-card--4 {
  width: 100%;
}

@media screen and (width >= 720px) {
  body.micro-site .grid .grid-card--4 {
    width: 50%;
  }
}

@media screen and (width >= 900px) {
  body.micro-site .grid .grid-card--4 {
    width: 25%;
  }
}

body.micro-site .grid .grid-card--image-background .inner {
  position: relative;
}

body.micro-site .grid .grid-card--image-background .inner.black {
  background-color: #000000a6;
}

body.micro-site .grid .grid-card--image-background .inner.red {
  background-color: #3616188c;
}

body.micro-site .grid .grid-card--image-background .inner :not(.button) {
  color: #fff;
}

body.micro-site .grid .grid-card--image-background__image {
  z-index: -1;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

body.micro-site .grid .grid-card--image-background__image img {
  object-fit: cover;
  transform-origin: center;
  width: 100%;
  height: 100%;
  transition: transform .25s ease-in;
}

body.micro-site .grid .grid-card--image-background__overlay {
  width: 100%;
  height: 100%;
}

body.micro-site .grid .grid-card--image-background:hover img, body.micro-site .grid .grid-card--image-background:focus-visible img {
  transform: scale(1.1);
}

body.micro-site .grid .grid-card--image-background:hover .button--outline-white, body.micro-site .grid .grid-card--image-background:focus-visible .button--outline-white {
  color: #000;
  background-color: #fff;
}

body.micro-site .grid .grid-card--image-top .inner {
  margin-bottom: 2rem;
  padding: 0 !important;
}

body.micro-site .grid .grid-card--image-top * {
  color: #000 !important;
}

body.micro-site .grid .grid-card--image-top__image {
  aspect-ratio: 2;
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
  overflow: hidden;
}

@media screen and (width >= 900px) {
  body.micro-site .grid .grid-card--image-top__image {
    aspect-ratio: 1;
    margin-bottom: 1.5rem;
  }
}

body.micro-site .grid .grid-card--image-top__image img {
  object-fit: cover;
  transform-origin: center;
  width: 100%;
  height: 100%;
  transition: transform .25s ease-in;
}

body.micro-site .grid .grid-card--image-top__pre-heading {
  text-transform: uppercase;
  margin-bottom: .25rem;
  font-family: Arial;
  font-size: .875rem;
  font-weight: 400;
}

@media screen and (width >= 900px) {
  body.micro-site .grid .grid-card--image-top__pre-heading {
    margin: 0;
  }
}

body.micro-site .grid .grid-card--image-top__heading {
  margin: .5rem 0 1rem;
}

body.micro-site .grid .grid-card--image-top .grid-card__read-more {
  margin: 0;
}

body.micro-site .grid .grid-card--image-top:hover img:not(.grid-card__logo), body.micro-site .grid .grid-card--image-top:focus-visible img:not(.grid-card__logo) {
  transform: scale(1.1);
}

body.micro-site .grid .grid-card--image-top:hover .grid-card__read-more, body.micro-site .grid .grid-card--image-top:focus-visible .grid-card__read-more {
  text-underline-offset: .75rem;
  color: #d7323c !important;
}

@media screen and (width >= 720px) {
  body.micro-site .grid .grid-card--align-bottom .inner {
    flex-flow: column;
    justify-content: space-between;
    height: 100%;
    display: flex;
  }

  body.micro-site .grid .grid-card--align-bottom .inner .grow {
    flex-grow: 1;
  }
}

body.micro-site .grid .grid-card .inner {
  width: 100%;
  height: 100%;
}

body.micro-site .grid .grid-gap {
  flex: 1 1 0;
}

body.micro-site h1, body.micro-site h2, body.micro-site h3, body.micro-site h4 {
  text-transform: none;
}

body.micro-site h1 {
  text-transform: none;
  font-family: Exo, sans-serif;
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 110%;
}

@media screen and (width >= 900px) {
  body.micro-site h1 {
    font-size: 3rem;
  }
}

@media screen and (width >= 1113px) {
  body.micro-site h1 {
    font-size: 3.5rem;
  }
}

@media screen and (width >= 1440px) {
  body.micro-site h1 {
    font-size: 4rem;
  }
}

body.micro-site .heading-2 {
  text-transform: none;
  font-family: Exo, sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 120%;
}

@media screen and (width >= 900px) {
  body.micro-site .heading-2 {
    font-size: 2rem;
  }
}

@media screen and (width >= 1113px) {
  body.micro-site .heading-2 {
    font-size: 2.375rem;
  }
}

body.micro-site .heading-3 {
  text-transform: none;
  font-family: Exo, sans-serif;
  font-size: 1.625rem;
  font-weight: 500;
  line-height: 115%;
}

body.micro-site .subheading {
  text-transform: none;
  font-family: Arial;
  font-size: 1.625rem;
  font-weight: 400;
}

body.micro-site p, body.micro-site .body, body.micro-site ul, body.micro-site ol {
  font-family: Arial;
  font-size: 1rem;
  font-weight: 400;
  line-height: 180%;
}

body.micro-site strong, body.micro-site .body-bold, body.micro-site .body.bold {
  font-family: Arial;
  font-size: 1rem;
  font-weight: 700;
  line-height: 180%;
}

body.micro-site .body-big {
  font-family: Arial;
  font-size: 1.125rem;
  font-weight: 400;
}

body.micro-site .body-big strong, body.micro-site .body-big.bold {
  font-family: Arial;
  font-size: 1.125rem;
  font-weight: 700;
}

body.micro-site .body-small {
  font-family: Arial;
  font-size: .875rem;
  font-weight: 400;
}

body.micro-site .body-small strong, body.micro-site .body-small.bold {
  font-family: Arial;
  font-size: .875rem;
  font-weight: 700;
}

body.micro-site .microsite-rte .color--red {
  color: #d7323c;
}

body.micro-site .microsite-rte .color--light-red {
  color: #dc5564;
}

body.micro-site .microsite-rte .color--grey {
  color: #606d63;
}

body.micro-site .microsite-rte .link {
  margin: 0 auto;
}

body.micro-site p a:not(.button):not(.grid-card), body.micro-site a.microsite-breadcrumbs___item__link {
  text-underline-offset: .2rem;
  color: #000;
  text-decoration: underline;
  transition: all .15s ease-in;
}

body.micro-site p a:not(.button):not(.grid-card):hover, body.micro-site p a:not(.button):not(.grid-card):focus-visible, body.micro-site a.microsite-breadcrumbs___item__link:hover, body.micro-site a.microsite-breadcrumbs___item__link:focus-visible {
  text-underline-offset: .4rem;
}

body.micro-site a:not(.button):not(.grid-card):not(.link-no-icon):not(.logotype)[target="_blank"]:after {
  content: "";
  vertical-align: middle;
  background-image: url("../external-link.f1807124.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: .75rem;
  height: .75rem;
  margin: 0 4px;
  display: inline-block;
}

body.micro-site .text-link {
  color: #000;
  text-underline-offset: .5rem;
  font-family: Exo, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  text-decoration: underline;
  transition: all .15s ease-in;
}

body.micro-site .text-link:focus-visible {
  outline-offset: 1px;
  outline: 2px solid #d7323c;
}

body.micro-site .text-link:hover, body.micro-site .text-link:focus-visible {
  text-underline-offset: .75rem;
}

body.micro-site .container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

body.micro-site .container-m {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

body.micro-site .container-s {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

body.micro-site .overlay-image-container {
  overflow: hidden;
}

body.micro-site img.red {
  outline-offset: -800px;
  filter: brightness(1.25) contrast(1.1);
  outline: 800px solid #3616188c;
}

body.micro-site img.black {
  outline-offset: -800px;
  filter: brightness(1.5) contrast(1.1);
  outline: 800px solid #000000a6;
}

body.micro-site .microsite-breadcrumbs {
  background-color: #f9f9f8;
  padding-top: 8px;
  padding-bottom: 8px;
}

body.micro-site .microsite-breadcrumbs__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

body.micro-site .microsite-breadcrumbs__list li {
  display: inline-block;
}

body.micro-site .microsite-breadcrumbs__list * {
  color: #171b1c;
  font-family: Arial;
  font-size: .875rem;
  font-weight: 400;
  line-height: 110%;
}

body.micro-site .microsite-breadcrumbs__item a {
  text-decoration: none;
}

body.micro-site .microsite-breadcrumbs__item a:hover {
  text-decoration: underline;
}

@media screen and (hover: none) {
  body.micro-site .microsite-breadcrumbs__item a {
    text-decoration: underline;
  }
}

body.micro-site .microsite-breadcrumbs__item a:focus-visible {
  outline-offset: 1px;
  outline: 2px solid #d7323c;
}

body.micro-site .microsite-breadcrumbs__item--divider {
  vertical-align: middle;
  display: inline-block;
}

body.micro-site .microsite-breadcrumbs__item--current span {
  font-weight: 700;
}

body.micro-site .microsite-fullscreen-layout {
  min-height: 100vh;
  position: relative;
}

body.micro-site .microsite-fullscreen-layout__background {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

body.micro-site .microsite-fullscreen-layout__background img {
  object-fit: cover;
  width: 100%;
  max-width: none;
  height: 100%;
}

body.micro-site .microsite-fullscreen-layout__overlay {
  position: absolute;
  inset: 0;
}

body.micro-site .microsite-fullscreen-layout__overlay.black {
  background-color: #000000a6;
}

body.micro-site .microsite-fullscreen-layout__overlay.red {
  background-color: #3616188c;
}

body.micro-site .microsite-fullscreen-layout__overlay :not(.button) {
  text-align: center;
  color: #fff !important;
}

body.micro-site .microsite-fullscreen-layout__overlay .container-s {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 72px;
  display: flex;
}

body.micro-site .microsite-fullscreen-layout__buttons {
  padding-top: 24px;
}

body.micro-site .microsite-fullscreen-layout__buttons .button {
  margin: 8px;
}

.microsite-filter {
  padding-bottom: 8px;
}

@media screen and (width >= 900px) {
  .microsite-filter {
    padding-bottom: 0;
  }
}

.microsite-filter__toggle {
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.microsite-filter__toggle svg {
  transform-origin: center;
  width: .875rem;
  height: .875rem;
}

.microsite-filter__toggle svg path {
  fill: none;
  stroke: #fff;
  stroke-width: 1px;
  transition: stroke .25s;
}

.microsite-filter__toggle:hover path, .microsite-filter__toggle:focus-visible path {
  stroke: #d7323c;
}

@media screen and (width >= 900px) {
  .microsite-filter__toggle {
    display: none !important;
  }
}

.microsite-filter__container {
  flex-flow: column;
  padding-top: 12px;
  display: flex;
}

@media screen and (width >= 900px) {
  .microsite-filter__container {
    flex-flow: wrap;
    justify-content: flex-start;
    margin-bottom: 0;
    padding-top: 8px;
  }
}

.microsite-filter__item {
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}

.microsite-filter__item:focus-visible {
  outline-offset: 1px;
  outline: 2px solid #d7323c;
}

@media screen and (width <= 900px) {
  .microsite-filter__item {
    text-align: left;
    margin: 4px 6px;
    font-family: Arial;
    font-size: 1rem;
    font-weight: 400;
    line-height: 180%;
    display: inline-block;
  }

  .microsite-filter__item:hover, .microsite-filter__item:focus-visible, .microsite-filter__item.mixitup-control-active {
    font-weight: 700;
  }

  .microsite-filter__item.mixitup-control-active:after {
    content: "";
    background-image: url("../check.68a9cf3e.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: .875rem;
    height: .875rem;
    margin-left: 4px;
    display: inline-block;
  }
}

@media screen and (width >= 900px) {
  .microsite-filter__item {
    color: #d7323c;
    background-color: #0000;
    border: 1px solid #d7323c;
    min-width: 240px;
    margin: 16px 16px 0 0;
    padding: 8px 24px;
    font-family: Exo, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    transition: color .25s, background-color .25s;
  }

  .microsite-filter__item:hover, .microsite-filter__item:focus-visible, .microsite-filter__item.mixitup-control-active {
    color: #fff;
    background-color: #d7323c;
  }
}

.microsite-filter__pagination {
  text-align: center;
}

.microsite-filter__pagination .mixitup-page-list {
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
  display: flex;
}

@media screen and (width >= 900px) {
  .microsite-filter__pagination .mixitup-page-list {
    padding-top: 56px;
  }
}

.microsite-filter__pagination .mixitup-page-list button {
  color: #000;
  transform-origin: center;
  background: none;
  border: none;
  outline: none;
  margin: 4px;
  padding: 4px;
  font-family: Arial;
  font-size: 1rem;
  font-weight: 400;
  line-height: 180%;
  transition: transform .25s, color .25s;
}

.microsite-filter__pagination .mixitup-page-list button:focus-visible {
  outline-offset: 1px;
  outline: 2px solid #d7323c;
}

.microsite-filter__pagination .mixitup-page-list button.mixitup-page-prev, .microsite-filter__pagination .mixitup-page-list button.mixitup-page-next {
  margin: 4px 8px;
}

.microsite-filter__pagination .mixitup-page-list button.mixitup-page-prev:not([disabled]):hover, .microsite-filter__pagination .mixitup-page-list button.mixitup-page-prev:not([disabled]):focus-visible, .microsite-filter__pagination .mixitup-page-list button.mixitup-page-next:not([disabled]):hover, .microsite-filter__pagination .mixitup-page-list button.mixitup-page-next:not([disabled]):focus-visible {
  transform: scale(1.2);
}

.microsite-filter__pagination .mixitup-page-list button.mixitup-page-prev[disabled], .microsite-filter__pagination .mixitup-page-list button.mixitup-page-next[disabled] {
  opacity: .4;
  pointer-events: none;
}

.microsite-filter__pagination .mixitup-page-list button.mixitup-page:hover, .microsite-filter__pagination .mixitup-page-list button.mixitup-page:focus-visible, .microsite-filter__pagination .mixitup-page-list button.mixitup-page.mixitup-page-active {
  color: #d7323c;
  transform: scale(1.4);
}

.microsite-footer {
  background: #1a1a1a;
  border-top: 1px solid #fff;
  padding: 48px 0;
}

@media screen and (width >= 900px) {
  .microsite-footer {
    padding: 104px 0;
  }
}

.microsite-footer * {
  color: #fff !important;
}

.microsite-footer__container {
  flex-flow: column;
  display: flex;
}

@media screen and (width >= 900px) {
  .microsite-footer__container {
    flex-flow: row;
    justify-content: space-between;
  }
}

.microsite-footer__container__section {
  text-align: center;
  max-width: 400px;
  margin: 24px auto;
}

@media screen and (width >= 900px) {
  .microsite-footer__container__section {
    text-align: left;
    max-width: none;
    margin: 0;
  }
}

@media screen and (width <= 900px) {
  .microsite-footer__container__section h2.heading-3 {
    margin-bottom: .4rem;
  }
}

.microsite-footer__links {
  width: 100%;
}

@media screen and (width >= 900px) {
  .microsite-footer__links {
    width: 25%;
  }
}

.microsite-footer__links__list {
  column-count: 2;
  max-width: 280px;
  margin: auto;
  padding: 0;
  list-style: none;
}

@media screen and (width >= 900px) {
  .microsite-footer__links__list {
    max-width: 100%;
    margin: 0;
    padding-right: 40px;
  }
}

.microsite-footer__links__list__item {
  margin: 0;
  font-family: Arial;
  font-size: .875rem;
  font-weight: 400;
}

.microsite-footer__links__list__item a {
  text-underline-offset: .2rem;
  color: #fff;
  transition: all .15s ease-in;
}

.microsite-footer__links__list__item a:focus-visible {
  outline-offset: 1px;
  outline: 2px solid #d7323c;
}

.microsite-footer__links__list__item a:hover, .microsite-footer__links__list__item a:focus-visible {
  text-underline-offset: .4rem;
  text-decoration: underline;
}

.microsite-footer__offices {
  width: 100%;
}

@media screen and (width >= 900px) {
  .microsite-footer__offices {
    flex-flow: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 55%;
    display: flex;
  }
}

.microsite-footer__offices__office {
  margin-bottom: 48px;
}

@media screen and (width >= 900px) {
  .microsite-footer__offices__office {
    width: 50%;
    padding-right: 16px;
  }
}

@media screen and (width >= 1113px) {
  .microsite-footer__offices__office {
    width: 33.3333%;
  }
}

.microsite-footer__offices__office__text * {
  margin: 0;
  font-size: .875rem !important;
}

.microsite-footer__offices__office__text * a {
  text-underline-offset: .2rem;
  color: #fff;
  transition: all .15s ease-in;
  text-decoration: none !important;
}

.microsite-footer__offices__office__text * a:focus-visible {
  outline-offset: 1px;
  outline: 2px solid #d7323c;
}

.microsite-footer__offices__office__text * a:hover, .microsite-footer__offices__office__text * a:focus-visible {
  text-underline-offset: .4rem;
  text-decoration: underline !important;
}

.microsite-footer__info {
  width: 100%;
}

@media screen and (width >= 900px) {
  .microsite-footer__info {
    width: 20%;
  }
}

.microsite-footer__info__logo {
  margin-bottom: 48px;
}

@media screen and (width >= 900px) {
  .microsite-footer__info__logo {
    text-align: right;
  }
}

.microsite-footer__info__social-links__list {
  flex-flow: wrap;
  justify-content: space-around;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

@media screen and (width >= 900px) {
  .microsite-footer__info__social-links__list {
    justify-content: flex-end;
  }

  .microsite-footer__info__social-links__list__item {
    margin-left: 32px;
  }
}

.microsite-footer__info__social-links__list__item a:focus-visible {
  outline-offset: 1px;
  outline: 2px solid #fff;
}

.microsite-footer__info__social-links__list__item a svg path {
  transition: stroke .25s;
}

.microsite-footer__info__social-links__list__item a:hover svg path, .microsite-footer__info__social-links__list__item a:focus-visible svg path {
  stroke: #d7323c;
}

.microsite-hero {
  width: 100%;
  max-width: 100%;
  min-height: 700px;
  position: relative;
  overflow: hidden;
}

.microsite-hero__background {
  z-index: -1;
  position: absolute;
  inset: 0;
}

.microsite-hero__background img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.microsite-hero__overlay {
  min-height: 700px;
  padding: 120px 0 88px;
}

@media screen and (width >= 900px) {
  .microsite-hero__overlay {
    padding: 144px 0 120px;
  }
}

@media screen and (width >= 1113px) {
  .microsite-hero__overlay {
    padding: 160px 0 144px;
  }
}

@media screen and (width >= 1440px) {
  .microsite-hero__overlay {
    padding: 200px 0 182px;
  }
}

.microsite-hero__overlay.black {
  background-color: #000000a6;
}

.microsite-hero__overlay.red {
  background-color: #3616188c;
}

.microsite-hero__content {
  max-width: 640px;
}

.microsite-hero__heading {
  color: #fff;
  padding-bottom: 8px;
}

.microsite-hero__preamble {
  color: #fff;
  margin-top: 16px;
  line-height: 180%;
}

.microsite-hero__button-container {
  margin-top: 32px;
}

.microsite-primary-navigation-mobile {
  font-family: Exo, sans-serif;
}

.microsite-primary-navigation-mobile .mobile-menu.level-2 > li {
  border-bottom: 1px solid #e5e5e5;
}

.microsite-primary-navigation-mobile .mobile-menu.level-2 li > span {
  justify-content: space-between;
  padding: .5rem;
  font-family: Exo, sans-serif;
  display: flex;
}

.microsite-primary-navigation-mobile .mobile-menu.level-2 li > span a {
  color: #000;
}

.microsite-primary-navigation-mobile .mobile-menu.level-2 li > span a:after {
  fill: #000;
}

.microsite-primary-navigation-mobile .mobile-menu.level-2 li > span .open-level-3 {
  padding: 4px;
  transform: rotate(90deg);
}

.microsite-primary-navigation-mobile .mobile-menu.level-2 li > span .open-level-3.rotate {
  transform: rotate(0);
}

.microsite-primary-navigation-mobile .mobile-menu.level-2 .level-3 {
  margin-left: 8px;
  display: none;
}

.microsite-primary-navigation-mobile .mobile-menu.level-2 .level-3 .open-level-4 {
  padding: 4px;
  transform: rotate(90deg);
}

.microsite-primary-navigation-mobile .mobile-menu.level-2 .level-3 .open-level-4.rotate {
  transform: rotate(0);
}

.microsite-primary-navigation-mobile .mobile-menu.level-2 .level-3.open {
  display: block;
}

.microsite-primary-navigation-mobile .mobile-menu.level-2 .level-3 .level-4 {
  margin-left: 8px;
  display: none;
}

.microsite-primary-navigation-mobile .mobile-menu.level-2 .level-3 .level-4 .open-level-5 {
  padding: 4px;
  transform: rotate(90deg);
}

.microsite-primary-navigation-mobile .mobile-menu.level-2 .level-3 .level-4 .open-level-5.rotate {
  transform: rotate(0);
}

.microsite-primary-navigation-mobile .mobile-menu.level-2 .level-3 .level-4.open {
  display: block;
}

.microsite-primary-navigation-mobile .mobile-menu.level-2 .level-3 .level-4 .level-5 {
  margin-left: 8px;
  display: none;
}

.microsite-primary-navigation-mobile .mobile-menu.level-2 .level-3 .level-4 .level-5.open {
  display: block;
}

.microsite-primary-navigation-mobile .external-navigation {
  border-top: 1px solid #e5e5e5;
  margin-top: 44.8px;
}

.microsite-primary-navigation-desktop {
  flex-flow: row;
  align-items: center;
  display: flex;
}

.microsite-primary-navigation-desktop a, .microsite-primary-navigation-desktop p {
  margin: 0;
  font-family: Exo, sans-serif !important;
}

.microsite-primary-navigation-desktop > ul {
  flex-flow: row;
  max-width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.microsite-primary-navigation-desktop .level-2.mega-menu details:hover {
  cursor: pointer;
}

.microsite-primary-navigation-desktop .level-2.mega-menu summary:after {
  content: "";
  border: none;
  margin: 0;
  padding: 0;
}

.microsite-primary-navigation-desktop .level-3.mega-menu {
  background-color: #fff;
  width: 100%;
  margin-left: 0;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}

.microsite-primary-navigation-desktop .level-3.mega-menu .inner {
  flex-direction: column;
  display: flex;
}

.microsite-primary-navigation-desktop .level-3.mega-menu .inner .top {
  margin-bottom: 3.38rem;
}

.microsite-primary-navigation-desktop .level-3.mega-menu .inner .top .text {
  color: #000;
  margin: 0 0 1.5rem;
  font-weight: 700;
}

.microsite-primary-navigation-desktop .level-3.mega-menu .inner .top .services {
  gap: 1.5rem;
  display: flex;
}

.microsite-primary-navigation-desktop .level-3.mega-menu .inner .top .services-link {
  margin-top: 1rem;
}

.microsite-primary-navigation-desktop .level-3.mega-menu .inner .bottom {
  justify-content: space-between;
  display: flex;
}

.microsite-primary-navigation-desktop .level-3.mega-menu .inner .bottom .playbook .text {
  color: #000;
  margin: 0;
  font-weight: 700;
}

.microsite-primary-navigation-desktop .level-3.mega-menu .inner .bottom .mega-menu-buttons {
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  max-width: 32.8125rem;
  display: flex;
}

.microsite-primary-navigation-desktop .level-3.mega-menu .inner .bottom .mega-menu-buttons .button {
  justify-content: center;
  display: flex;
}

.microsite-primary-navigation-desktop .level-2 {
  padding: 0 8px;
}

.microsite-primary-navigation-desktop .level-2 .level-2-link {
  color: #fff;
}

.microsite-primary-navigation-desktop .level-2 .level-2-link.current-page {
  border-bottom: 1px solid #fff;
}

.microsite-primary-navigation-desktop .level-2 .level-2-link:focus-visible {
  outline: 2px solid #d7323c;
}

.microsite-primary-navigation-desktop .level-2 > .level-2-link:not(.current-page):hover, .microsite-primary-navigation-desktop .level-2 > .level-2-link:not(.current-page):focus-within {
  border-bottom: 1px solid #fff;
}

.microsite-primary-navigation-desktop .level-2 summary {
  justify-content: space-between;
  align-items: center;
  list-style: none;
  display: flex;
}

.microsite-primary-navigation-desktop .level-2 summary.current-page {
  border-bottom: 1px solid #fff;
}

.microsite-primary-navigation-desktop .level-2 summary:not(.current-page) {
  border-bottom: 1px solid #0000;
}

.microsite-primary-navigation-desktop .level-2 summary:not(.current-page):hover {
  border-bottom: 1px solid #fff;
}

.microsite-primary-navigation-desktop .level-2 summary:focus-visible {
  outline: 2px solid #d7323c;
}

.microsite-primary-navigation-desktop .level-2 summary:after {
  content: "";
  border-top: 6px solid #fff;
  border-inline: 4px solid #0000;
  width: 0;
  height: 0;
  margin-left: 8px;
  padding-bottom: 5px;
  transition: all .2s;
}

.microsite-primary-navigation-desktop .level-2 summary:hover .level-2-link {
  padding: 0;
}

.microsite-primary-navigation-desktop .level-2 summary:hover .level-2-link:hover {
  border: none;
}

.microsite-primary-navigation-desktop .level-2 summary .level-2-link {
  padding: 0;
}

.microsite-primary-navigation-desktop .level-2 details[open] > summary:after {
  transform: rotate(-180deg);
}

.microsite-primary-navigation-desktop .level-2 summary::-webkit-details-marker {
  display: none;
}

.microsite-primary-navigation-desktop .level-2 details[open] summary {
  border-bottom: 2px solid #fff;
  border-radius: 5px 5px 0 0;
}

.microsite-primary-navigation-desktop .level-2.scrolled summary:hover {
  border-bottom: 1px solid #000;
}

.microsite-primary-navigation-desktop .level-2.scrolled summary:after {
  filter: invert() !important;
}

.microsite-primary-navigation-desktop .level-2.scrolled .level-2-link {
  color: #000 !important;
}

.microsite-primary-navigation-desktop .level-2 .level-3 {
  background-color: #fff;
  flex-direction: column;
  padding: 0 26px;
  display: flex;
  position: absolute;
  top: 100%;
}

.microsite-primary-navigation-desktop .level-2 .level-3.mega-menu {
  width: 100%;
  position: absolute;
  left: 0;
}

.microsite-primary-navigation-desktop .level-2 .level-3.mega-menu .inner {
  width: 100%;
}

.microsite-primary-navigation-desktop .level-2 .level-3 li {
  padding: 12px 0;
}

.microsite-primary-navigation-desktop .level-2 .level-3 li summary:hover, .microsite-primary-navigation-desktop .level-2 .level-3 li .link:hover {
  border-bottom: 1px solid #000;
}

.microsite-primary-navigation-desktop .level-2 .level-3 li a:focus-visible {
  outline: 2px solid #d7323c;
}

.microsite-primary-navigation-desktop .level-2 .level-3 a:not(.button--outline-black):not(.button--fill-red) {
  color: #000;
}

.microsite-primary-navigation-desktop .level-2 .level-3 .level-4 {
  background-color: #fff;
  flex-direction: column;
  padding: 0;
  display: flex;
}

.microsite-primary-navigation-desktop .level-2 .level-2-link {
  padding-bottom: 5px;
}

.microsite-primary-navigation-desktop .button-list.scrolled .button--outline-white {
  color: #000 !important;
  background-color: #0000 !important;
  border: 1px solid #000 !important;
}

.microsite-primary-navigation-desktop .button-list.scrolled .button--outline-white:hover {
  color: #fff !important;
  background-color: #000 !important;
}

.microsite-primary-navigation-desktop a.active details summary, .microsite-primary-navigation-desktop a:focus-within details summary {
  border-bottom: 1px solid #fff;
}

.current-page {
  border-bottom: 1px solid #000;
}

.micro-site-header {
  z-index: 20;
  background-color: #0000;
  height: 80px;
  transition: background .3s ease-in;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

@media (width >= 1113px) {
  .micro-site-header {
    height: 118px;
  }
}

.micro-site-header:focus-visible {
  border: 2px solid #d7323c;
}

.micro-site-header .top-navigation {
  display: none;
}

@media (width >= 1113px) {
  .micro-site-header .top-navigation {
    background: #0006;
    transition: background .3s ease-in;
    display: block;
    box-shadow: 0 10px 20px #0000001a;
  }

  .micro-site-header .top-navigation .container {
    justify-content: flex-end;
    gap: 1.875rem;
    padding: .5rem 4rem 0;
    display: flex;
  }

  .micro-site-header .top-navigation .container .dropdown {
    padding: 0 16px;
    position: relative;
  }

  .micro-site-header .top-navigation .container .dropdown .level-2 {
    color: #fff;
    font-family: Exo, sans-serif;
  }

  .micro-site-header .top-navigation .container .dropdown .level-2 details summary {
    color: #fff;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    display: flex;
  }

  .micro-site-header .top-navigation .container .dropdown .level-2 details summary:after {
    content: "";
    border-top: 6px solid #fff;
    border-inline: 4px solid #0000;
    width: 0;
    height: 0;
    margin-left: 8px;
    padding-bottom: 5px;
    transition: all .2s;
  }

  .micro-site-header .top-navigation .container .dropdown .level-2 details summary:hover {
    border-bottom: 1px solid;
  }

  .micro-site-header .top-navigation .container .dropdown .level-2 details ul {
    background-color: #fff;
    min-height: 80px;
    padding: 12px 16px;
    font-family: Exo, sans-serif;
    position: absolute;
    left: 0;
    right: 0;
    box-shadow: 0 2px 5px #0000004d, 2px 0 5px -5px #0000004d, -2px 0 5px -5px #0000004d;
  }

  .micro-site-header .top-navigation .container .dropdown .level-2 details ul li {
    padding: 12px 0;
  }

  .micro-site-header .top-navigation .container .dropdown .level-2 details ul li a {
    color: #000;
    display: flex;
  }

  .micro-site-header .top-navigation .container .dropdown .level-2 details ul li a svg path {
    stroke: #000;
  }

  .micro-site-header .top-navigation .container .dropdown .level-2 details[open] > summary:after {
    transform: rotate(-180deg);
  }

  .micro-site-header .top-navigation .container .dropdown .level-2.scrolled {
    color: #000;
  }

  .micro-site-header .top-navigation .container .dropdown .level-2.scrolled summary:after {
    filter: invert() !important;
  }

  .micro-site-header .top-navigation .container .dropdown .level-2.scrolled .level-2-link {
    color: #000 !important;
  }

  .micro-site-header .top-navigation .container .dropdown .level-2.scrolled .level-3-link:hover {
    border-bottom: 1px solid;
  }

  .micro-site-header .top-navigation .container .menu-links .level-2 {
    font-family: Exo, sans-serif;
  }

  .micro-site-header .top-navigation .container .menu-links .level-2:hover {
    border-bottom: 1px solid #fff;
  }

  .micro-site-header .top-navigation .container .menu-links .level-2 .level-2-link {
    color: #fff;
    display: flex;
  }

  .micro-site-header .top-navigation .container .menu-links .level-2 .level-2-link[target="_blank"]:after {
    filter: invert() !important;
  }

  .micro-site-header .top-navigation .container .menu-links .level-2.scrolled .level-2-link {
    color: #000 !important;
  }

  .micro-site-header .top-navigation .container .menu-links .level-2.scrolled .level-2-link svg path {
    stroke: #000;
  }

  .micro-site-header .top-navigation .container .menu-links .level-2.scrolled .level-2-link[target="_blank"]:after {
    filter: invert(0) !important;
  }
}

.micro-site-header .inner {
  justify-content: space-between;
  padding: .938rem 4rem;
  display: flex;
}

.micro-site-header .inner .logo-black {
  width: 100%;
  max-width: 7rem;
  display: none;
}

.micro-site-header .inner .logo-black:focus-visible {
  border: 2px solid #d7323c;
}

.micro-site-header .inner .logo-white {
  width: 100%;
  max-width: 7rem;
  display: block;
}

.micro-site-header .inner .logo-white:focus-visible {
  border: 2px solid #d7323c;
}

.micro-site-header .inner .microsite-primary-navigation-mobile {
  z-index: 20;
  background-color: #0000;
  transition: background .3s ease-in;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

@media (width >= 1113px) {
  .micro-site-header .inner .microsite-primary-navigation-mobile {
    display: none;
  }
}

.micro-site-header .inner .microsite-primary-navigation-mobile .menu-toggle {
  cursor: pointer;
  z-index: 6;
  width: 30px;
  height: 30px;
  margin: 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.micro-site-header .inner .microsite-primary-navigation-mobile .menu-toggle .bar {
  background-color: #fff;
  width: 100%;
  height: 3px;
  margin: 5px 0;
  transition: all .4s;
}

.micro-site-header .inner .microsite-primary-navigation-mobile #overlay {
  color: #000;
  z-index: 5;
  background-color: #fff;
  width: 100%;
  height: 100%;
  transition: all .4s;
  position: fixed;
  top: 80px;
  right: -500px;
  overflow-y: auto;
}

@media (width >= 500px) {
  .micro-site-header .inner .microsite-primary-navigation-mobile #overlay {
    width: 500px;
  }
}

.micro-site-header .inner .microsite-primary-navigation-mobile #overlay.open {
  right: 0;
}

.micro-site-header .inner .microsite-primary-navigation-mobile .menu-toggle.active .bar:first-child {
  transform: rotate(-45deg)translate(-5px, 6px);
}

.micro-site-header .inner .microsite-primary-navigation-mobile .menu-toggle.active .bar:nth-child(2) {
  opacity: 0;
}

.micro-site-header .inner .microsite-primary-navigation-mobile .menu-toggle.active .bar:nth-child(3) {
  transform: rotate(45deg)translate(-5px, -6px);
}

.micro-site-header .inner .microsite-primary-navigation-desktop {
  display: none;
}

@media (width >= 1113px) {
  .micro-site-header .inner .microsite-primary-navigation-desktop {
    align-items: center;
    display: flex;
  }

  .micro-site-header .inner .microsite-primary-navigation-desktop ul {
    display: inline-flex;
  }
}

.micro-site-header .inner .button-list {
  gap: 1.8rem;
  margin-left: 4.75rem;
}

.micro-site-header .inner .button-list a {
  padding: .813rem 3.375rem;
}

.micro-site-header.scrolled {
  background-color: #fff;
  box-shadow: 0 0 20px 5px #0000001a;
}

.micro-site-header.scrolled .top-navigation {
  background-color: #fff;
}

.micro-site-header.scrolled .inner .logo-black {
  display: block;
}

.micro-site-header.scrolled .inner .logo-white {
  display: none;
}

.micro-site-header.scrolled .inner .microsite-primary-navigation .desktop-menu a {
  color: #000;
}

.micro-site-header.scrolled .inner .microsite-primary-navigation .desktop-menu .stroke {
  fill: #000;
  stroke: #000;
}

.micro-site-header.scrolled .inner .microsite-primary-navigation .button-list .button--outline-white {
  color: #000;
  border: 1px solid #000;
}

.micro-site-header.scrolled .inner .microsite-primary-navigation .button-list .button--outline-white:hover {
  color: #fff;
  background-color: #000;
}

.micro-site-header.scrolled .inner .microsite-primary-navigation-mobile .menu-toggle .bar {
  background-color: #000;
}

.microsite-accordion-block {
  background-color: #1a1a1a;
  padding-top: 16px;
  padding-bottom: 16px;
}

.microsite-accordion-block[id]:not([id=""]) {
  scroll-margin-top: 80px;
}

@media screen and (width >= 1113px) {
  .microsite-accordion-block[id]:not([id=""]) {
    scroll-margin-top: 120px;
  }
}

@media screen and (width >= 900px) {
  .microsite-accordion-block {
    background-image: url("../bg-dots-dark.8833c666.svg");
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.microsite-accordion-block__container {
  max-width: 960px !important;
}

.microsite-accordion-block__heading {
  color: #fff;
  padding-top: 2rem;
}

@media screen and (width >= 900px) {
  .microsite-accordion-block__heading {
    padding-top: 3rem;
  }
}

.microsite-accordion-block__items {
  margin: 0;
  padding: 0 0 2rem;
  list-style: none;
}

@media screen and (width >= 900px) {
  .microsite-accordion-block__items {
    padding-bottom: 1rem;
  }
}

.microsite-accordion-block__item {
  background-color: #fff;
  margin-bottom: 16px;
  padding: 16px 24px;
}

.microsite-accordion-block__item :not(.button) {
  color: #000;
}

.microsite-accordion-block__item__heading {
  text-align: left;
  background: none;
  border: none;
  outline: none;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
}

.microsite-accordion-block__item__heading .icon {
  transform-origin: center;
  font-size: .875rem;
  transform: scaleY(.8);
}

.microsite-accordion-block__item__heading.open .label {
  text-decoration: underline;
}

.microsite-accordion-block__item__heading.open .icon {
  transform: scaleY(.8)rotate(180deg);
}

.microsite-accordion-block__item__content :not(.button) {
  font-family: Arial;
  font-size: .875rem;
  font-weight: 400;
}

.microsite-accordion-block__item__content p:first-of-type {
  margin-top: .5rem;
}

.microsite-accordion-block__item__content .button-container .button {
  margin: 16px 16px 16px 0;
}

.microsite-accordion-block__button {
  margin-bottom: 2rem !important;
}

@media screen and (width >= 900px) {
  .microsite-accordion-block__button {
    margin-bottom: 3rem !important;
  }
}

.microsite-articles-grid-block {
  padding: 32px 0;
}

.microsite-articles-grid-block[id]:not([id=""]) {
  scroll-margin-top: 80px;
}

@media screen and (width >= 1113px) {
  .microsite-articles-grid-block[id]:not([id=""]) {
    scroll-margin-top: 120px;
  }
}

@media screen and (width >= 900px) {
  .microsite-articles-grid-block {
    padding: 56px 0;
  }
}

.microsite-articles-grid-block__heading {
  padding-bottom: 24px;
}

.microsite-articles-grid-block__heading h2, .microsite-articles-grid-block__heading a {
  vertical-align: middle;
  margin: 0;
  display: inline-block;
}

.microsite-articles-grid-block__heading h2 {
  margin-right: 32px;
}

@media screen and (width >= 900px) {
  .microsite-articles-grid-block .highlight-article:focus-visible {
    outline-offset: 1px;
    outline: 2px solid #d7323c;
  }

  .microsite-articles-grid-block .highlight-article .inner {
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    display: flex;
  }

  .microsite-articles-grid-block .highlight-article__image {
    aspect-ratio: 3 / 2;
    width: 50%;
    overflow: hidden;
  }

  .microsite-articles-grid-block .highlight-article__image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: transform .25s;
  }

  .microsite-articles-grid-block .highlight-article__text {
    width: 50%;
    padding: 0 32px;
  }

  .microsite-articles-grid-block .highlight-article__text * {
    color: #000;
    max-width: 560px;
  }

  .microsite-articles-grid-block .highlight-article__date {
    text-transform: uppercase;
  }

  .microsite-articles-grid-block .highlight-article__date, .microsite-articles-grid-block .highlight-article__heading {
    margin-bottom: .5rem;
  }

  .microsite-articles-grid-block .highlight-article:hover img, .microsite-articles-grid-block .highlight-article:focus-visible img {
    transform: scale(1.1);
  }

  .microsite-articles-grid-block .highlight-article:hover .text-link, .microsite-articles-grid-block .highlight-article:focus-visible .text-link {
    text-underline-offset: .75rem;
    color: #d7323c !important;
  }
}

.microsite-articles-slider-block {
  background-color: #f9f9f8;
  max-width: 100%;
  padding: 32px 0;
  overflow: hidden;
}

.microsite-articles-slider-block[id]:not([id=""]) {
  scroll-margin-top: 80px;
}

@media screen and (width >= 1113px) {
  .microsite-articles-slider-block[id]:not([id=""]) {
    scroll-margin-top: 120px;
  }
}

@media screen and (width >= 900px) {
  .microsite-articles-slider-block {
    padding: 56px 0;
  }
}

.microsite-articles-slider-block .container {
  position: relative;
}

.microsite-articles-slider-block .splide__track {
  overflow: visible !important;
}

.microsite-articles-slider-block .splide__arrows {
  display: none;
  position: relative;
}

@media screen and (width >= 900px) {
  .microsite-articles-slider-block .splide__arrows {
    display: block;
  }
}

.microsite-articles-slider-block .splide__arrow {
  z-index: 80;
  transform-origin: center;
  background: #f1f2f2;
  border: none;
  border-radius: 50%;
  outline: none;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  transition: transform .25s, background .25s, opacity .25s;
  display: flex;
  position: absolute;
  bottom: -300px;
}

.microsite-articles-slider-block .splide__arrow--prev {
  left: -12px;
}

@media screen and (width >= 1440px) {
  .microsite-articles-slider-block .splide__arrow--prev {
    left: -32px;
  }
}

.microsite-articles-slider-block .splide__arrow--next {
  right: -12px;
}

@media screen and (width >= 1440px) {
  .microsite-articles-slider-block .splide__arrow--next {
    right: -32px;
  }
}

.microsite-articles-slider-block .splide__arrow:focus-visible {
  outline-offset: 1px;
  outline: 2px solid #d7323c;
}

@media screen and (width >= 1440px) {
  .microsite-articles-slider-block .splide__arrow {
    width: 64px;
    height: 64px;
  }
}

.microsite-articles-slider-block .splide__arrow svg {
  width: 16px;
  height: 16px;
}

.microsite-articles-slider-block .splide__arrow svg path {
  fill: #141d32;
}

.microsite-articles-slider-block .splide__arrow--prev svg {
  transform-origin: center;
  transform: rotate(180deg);
}

.microsite-articles-slider-block .splide__arrow:hover, .microsite-articles-slider-block .splide__arrow:focus-visible {
  background: #e8e8e8;
  transform: scale(1.2);
}

.microsite-articles-slider-block .splide__arrow:disabled {
  opacity: 0;
  pointer-events: none;
}

.microsite-articles-slider-block .splide__pagination {
  flex-flow: row;
  justify-content: flex-start;
  padding: 24px 0;
  display: flex;
}

.microsite-articles-slider-block .splide__pagination__page {
  background: none;
  border: none;
  outline: none;
  width: 40px;
  height: 16px;
  margin: 0 8px 0 0;
  padding: 0;
}

.microsite-articles-slider-block .splide__pagination__page:focus-visible {
  outline-offset: 1px;
  outline: 2px solid #d7323c;
}

.microsite-articles-slider-block .splide__pagination__page:after {
  content: "";
  background: #d0d0d0;
  width: 100%;
  height: 3px;
  transition: background .25s;
  display: block;
}

.microsite-articles-slider-block .splide__pagination__page.is-active:after, .microsite-articles-slider-block .splide__pagination__page:hover:after {
  background: #000;
}

.microsite-articles-slider-block__slide {
  display: block;
}

.microsite-articles-slider-block__slide:focus-visible {
  outline-offset: 1px;
  outline: 2px solid #d7323c;
}

.microsite-articles-slider-block__slide:hover img, .microsite-articles-slider-block__slide:focus-visible img {
  transform: scale(1.1);
}

.microsite-articles-slider-block__slide:hover .text-link, .microsite-articles-slider-block__slide:focus-visible .text-link {
  text-underline-offset: .75rem;
  color: #d7323c !important;
}

.microsite-articles-slider-block__slide__inner {
  flex-flow: column;
  justify-content: space-between;
  display: flex;
}

@media screen and (width >= 900px) {
  .microsite-articles-slider-block__slide__inner {
    flex-flow: row;
    align-items: center;
  }
}

.microsite-articles-slider-block__slide__image {
  aspect-ratio: 4 / 3;
  width: 100%;
  overflow: hidden;
}

@media screen and (width >= 900px) {
  .microsite-articles-slider-block__slide__image {
    width: 60%;
  }
}

.microsite-articles-slider-block__slide__image img {
  object-fit: cover;
  transform-origin: center;
  width: 100%;
  height: 100%;
  transition: transform .25s;
}

.microsite-articles-slider-block__slide__text * {
  color: #000 !important;
}

@media screen and (width >= 900px) {
  .microsite-articles-slider-block__slide__text {
    width: 40%;
    padding-left: 24px;
    padding-right: 8px;
  }
}

@media screen and (width >= 1113px) {
  .microsite-articles-slider-block__slide__text {
    padding-left: 64px;
    padding-right: 16px;
  }
}

.microsite-articles-slider-block__slide__date {
  text-transform: uppercase;
  margin-bottom: .5rem;
}

.microsite-articles-slider-block__slide__heading {
  margin-bottom: .5rem;
}

.microsite-blurb-block {
  padding: 32px 0;
}

.microsite-blurb-block[id]:not([id=""]) {
  scroll-margin-top: 80px;
}

@media screen and (width >= 1113px) {
  .microsite-blurb-block[id]:not([id=""]) {
    scroll-margin-top: 120px;
  }
}

@media screen and (width >= 900px) {
  .microsite-blurb-block {
    padding: 56px 0;
  }

  .microsite-blurb-block__grid {
    margin: 8px -8px !important;
  }
}

@media screen and (width >= 1113px) {
  .microsite-blurb-block__grid {
    flex-flow: row !important;
  }
}

.microsite-blurb-block__grid--left {
  flex-flow: wrap;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: space-between;
  width: 100%;
  display: flex;
}

@media screen and (width >= 720px) {
  .microsite-blurb-block__grid--left .grid-card {
    width: 50%;
  }
}

@media screen and (width >= 1113px) {
  .microsite-blurb-block__grid--left {
    width: 60%;
  }
}

.microsite-blurb-block__grid--right {
  flex-shrink: 1;
  width: 100%;
}

@media screen and (width >= 1113px) {
  .microsite-blurb-block__grid--right .grid-card {
    height: 100%;
  }
}

.microsite-blurb-block__card {
  aspect-ratio: 3 / 2;
  width: 100%;
}

@media screen and (width >= 900px) {
  .microsite-blurb-block__card {
    padding: 8px !important;
  }
}

@media screen and (width >= 1113px) {
  .microsite-blurb-block__card {
    aspect-ratio: 4 / 3;
  }
}

.microsite-blurb-block__card .inner {
  padding: 24px 32px 24px 24px;
}

.microsite-blurb-block__card__pre-heading {
  margin-bottom: .5rem;
  font-family: Arial;
  font-size: .875rem;
  font-weight: 400;
}

.microsite-blurb-block__card__heading {
  text-transform: none;
  margin-bottom: 2rem;
  font-family: Exo, sans-serif;
  font-size: 1.625rem;
  font-weight: 500;
  line-height: 115%;
}

.microsite-blurb-block .grid-card--image-background__overlay {
  flex-flow: column;
  justify-content: flex-end;
  align-items: flex-start;
  display: flex;
}

@media screen and (width >= 900px) {
  .microsite-blurb-block--2 .microsite-blurb-block__grid--left {
    width: 40%;
  }

  .microsite-blurb-block--2 .microsite-blurb-block__grid--left .grid-card {
    aspect-ratio: 2;
    width: 100%;
  }

  .microsite-blurb-block--4 .microsite-blurb-block__grid--left .grid-card:last-child {
    aspect-ratio: 3;
    width: 100%;
  }
}

@media screen and (width >= 900px) and (width >= 1113px) {
  .microsite-blurb-block--4 .microsite-blurb-block__grid--left .grid-card:last-child {
    aspect-ratio: 2;
  }
}

.microsite-hero-block {
  width: 100%;
  max-width: 100%;
  min-height: 450px;
  position: relative;
  overflow: hidden;
}

@media screen and (width >= 900px) {
  .microsite-hero-block {
    min-height: 700px;
  }
}

.microsite-hero-block .splide {
  position: relative;
  visibility: visible !important;
}

.microsite-hero-block .splide .splide__arrows {
  display: none;
}

@media screen and (width >= 1600px) {
  .microsite-hero-block .splide .splide__arrows {
    display: block;
  }
}

.microsite-hero-block .splide .splide__arrows .splide__arrow--prev {
  background-color: #0000;
  border: none;
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: rotate(180deg);
}

.microsite-hero-block .splide .splide__arrows .splide__arrow--prev svg {
  filter: invert();
}

.microsite-hero-block .splide .splide__arrows .splide__arrow--next {
  background-color: #0000;
  border: none;
  position: absolute;
  top: 50%;
  right: 1rem;
}

.microsite-hero-block .splide .splide__arrows .splide__arrow--next svg {
  filter: invert();
}

.microsite-hero-block .splide .splide__pagination {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
}

.microsite-hero-block .splide .splide__pagination__page {
  border: none;
  border-radius: 50%;
  width: 10px;
  height: 12px;
  margin: 1.5px;
}

.microsite-hero-block .splide .splide__pagination__page.is-active {
  background-color: #d7323c;
}

.microsite-hero-block .splide__slide {
  width: 100%;
  min-height: 450px;
}

@media screen and (width >= 900px) {
  .microsite-hero-block .splide__slide {
    min-height: 700px;
  }
}

.microsite-hero-block .microsite-hero__background {
  z-index: -1;
  position: absolute;
  inset: 0;
}

.microsite-hero-block .microsite-hero__background img, .microsite-hero-block .microsite-hero__background video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.microsite-hero-block .microsite-hero__overlay {
  padding: 120px 0 88px;
}

@media screen and (width >= 900px) {
  .microsite-hero-block .microsite-hero__overlay {
    padding: 144px 0 120px;
  }
}

@media screen and (width >= 1113px) {
  .microsite-hero-block .microsite-hero__overlay {
    padding: 160px 0 144px;
  }
}

@media screen and (width >= 1440px) {
  .microsite-hero-block .microsite-hero__overlay {
    padding: 200px 0 182px;
  }
}

.microsite-hero-block .microsite-hero__overlay.black {
  background-color: #000000a6;
  height: 100%;
}

.microsite-hero-block .microsite-hero__overlay.red {
  background-color: #3616188c;
  height: 100%;
}

.microsite-hero-block .microsite-hero__content {
  max-width: 640px;
}

.microsite-hero-block .microsite-hero__text * {
  color: #fff;
}

.microsite-hero-block .microsite-hero__button-container {
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 32px;
  display: flex;
}

.microsite-hero-block .microsite-hero__button-container a {
  flex: 0 100%;
  min-width: 200px;
}

@media screen and (width >= 375px) {
  .microsite-hero-block .microsite-hero__button-container a {
    flex: 0 40%;
  }
}

.microsite-hero-block .splide__progress__bar {
  background: #ccc;
  height: 3px;
}

.microsite-image-block {
  margin: 24px 0;
}

.microsite-image-block[id]:not([id=""]) {
  scroll-margin-top: 80px;
}

@media screen and (width >= 1113px) {
  .microsite-image-block[id]:not([id=""]) {
    scroll-margin-top: 120px;
  }
}

.microsite-reference-block {
  padding: 32px 0;
}

.microsite-reference-block[id]:not([id=""]) {
  scroll-margin-top: 80px;
}

@media screen and (width >= 1113px) {
  .microsite-reference-block[id]:not([id=""]) {
    scroll-margin-top: 120px;
  }
}

@media screen and (width >= 900px) {
  .microsite-reference-block {
    padding: 56px 0;
  }
}

.microsite-reference-block :not(.text-link) {
  color: #191919;
}

.microsite-reference-block__heading {
  padding-bottom: 24px;
}

.microsite-reference-block__heading h2, .microsite-reference-block__heading a {
  vertical-align: middle;
  margin: 0;
  display: inline-block;
}

.microsite-reference-block__heading h2 {
  margin-right: 32px;
}

.microsite-reference-block__card__inner {
  flex-flow: column;
  height: 100%;
  padding: 24px;
  transition: box-shadow .25s;
  display: flex;
  box-shadow: 0 6px 14px #9494940d, 0 25px 25px #9494940a, 0 56px 34px #94949408, 0 100px 40px #94949403, 0 156px 44px #94949400;
}

.microsite-reference-block__card__logo-container {
  height: 32px;
  margin: 5px 0 24px;
}

@media screen and (width >= 900px) {
  .microsite-reference-block__card__logo-container {
    height: 40px;
  }
}

.microsite-reference-block__card__text {
  flex-grow: 1;
  padding-bottom: 24px;
}

@media screen and (width >= 900px) {
  .microsite-reference-block__card__text {
    padding-bottom: 40px;
  }
}

.microsite-reference-block__card__text__quote {
  font-style: italic;
}

.microsite-reference-block__card:hover .microsite-reference-block__card__inner, .microsite-reference-block__card:focus-visible .microsite-reference-block__card__inner {
  box-shadow: 0 6px 14px #94949412, 0 25px 25px #9494940f, 0 56px 34px #9494940f, 0 70px 40px #9494940a, 0 156px 44px #9494940a;
}

.microsite-stats-block {
  background-color: #1a1a1a;
  padding-top: 16px;
  padding-bottom: 16px;
}

.microsite-stats-block[id]:not([id=""]) {
  scroll-margin-top: 80px;
}

@media screen and (width >= 1113px) {
  .microsite-stats-block[id]:not([id=""]) {
    scroll-margin-top: 120px;
  }
}

@media screen and (width >= 900px) {
  .microsite-stats-block {
    background-image: url("../bg-dots-dark.8833c666.svg");
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.microsite-stats-block * {
  color: #fbfbfb;
}

.microsite-stats-block .container-m {
  padding: 32px 0;
}

@media screen and (width >= 900px) {
  .microsite-stats-block .container-m {
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    padding: 56px 0;
    display: flex;
  }
}

.microsite-stats-block--left {
  padding-bottom: 24px;
}

@media screen and (width >= 900px) {
  .microsite-stats-block--left {
    padding-bottom: 0;
  }
}

.microsite-stats-block--left__container {
  width: 200px;
  height: 200px;
  margin: auto;
  position: relative;
}

@media screen and (width >= 1113px) {
  .microsite-stats-block--left__container {
    width: 300px;
    height: 300px;
  }
}

.microsite-stats-block--left__number, .microsite-stats-block--left__circle {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

.microsite-stats-block--left__number p {
  max-width: 80%;
  margin: 0;
}

.microsite-stats-block--left__circle .circle {
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.microsite-stats-block--right {
  text-align: center;
  padding-bottom: 24px;
}

@media screen and (width >= 900px) {
  .microsite-stats-block--right {
    text-align: left;
    flex-grow: 1;
    padding-bottom: 0;
    padding-left: 40px;
  }
}

@media screen and (width >= 1113px) {
  .microsite-stats-block--right {
    padding-left: 96px;
  }
}

.microsite-stats-block__heading, .microsite-stats-block__text {
  padding-right: 16px;
}

@media screen and (width <= 900px) {
  .microsite-stats-block__heading, .microsite-stats-block__text {
    margin: auto;
  }
}

@media screen and (width >= 900px) {
  .microsite-stats-block__heading, .microsite-stats-block__text {
    padding-right: 64px;
  }
}

.microsite-stats-block__heading {
  margin-bottom: 1rem;
}

.microsite-stats-block__items {
  flex-flow: column;
  justify-content: center;
  padding-top: 24px;
  display: flex;
}

@media screen and (width >= 720px) {
  .microsite-stats-block__items {
    grid-template-columns: repeat(3, 1fr);
    display: grid;
  }
}

@media screen and (width >= 900px) {
  .microsite-stats-block__items {
    justify-content: flex-start;
  }
}

.microsite-stats-block__items__item {
  padding: 16px;
}

.microsite-stats-block__items__item p {
  text-align: center;
  margin: 0;
}

@media screen and (width >= 900px) {
  .microsite-stats-block__items__item {
    padding: 16px 64px 26px 0;
  }
}

@keyframes draw {
  from {
    stroke-dasharray: 0 100;
  }
}

.microsite-text-block-article, .microsite-text-block {
  padding: 2rem 0;
}

.microsite-text-block-article[id]:not([id=""]), .microsite-text-block[id]:not([id=""]) {
  scroll-margin-top: 80px;
}

@media screen and (width >= 1113px) {
  .microsite-text-block-article[id]:not([id=""]), .microsite-text-block[id]:not([id=""]) {
    scroll-margin-top: 120px;
  }
}

.microsite-text-block-article h2, .microsite-text-block h2 {
  text-transform: none;
  font-family: Exo, sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 120%;
}

@media screen and (width >= 900px) {
  .microsite-text-block-article h2, .microsite-text-block h2 {
    font-size: 2rem;
  }
}

@media screen and (width >= 1113px) {
  .microsite-text-block-article h2, .microsite-text-block h2 {
    font-size: 2.375rem;
  }
}

.microsite-text-block-article p, .microsite-text-block p {
  color: #000;
  font-family: Arial;
  font-size: 1rem;
  font-weight: 400;
  line-height: 180%;
}

.microsite-text-block-article.margin-1, .microsite-text-block.margin-1 {
  margin: 2rem 0;
  padding: 2rem 0;
}

.microsite-text-block-article.margin-2, .microsite-text-block.margin-2 {
  margin: 4rem 0;
  padding: 6rem 0;
}

.microsite-text-block-article.margin-3, .microsite-text-block.margin-3 {
  margin: 4rem 0;
  padding: 8rem 0;
}

.microsite-text-block-article .content .text.width-1, .microsite-text-block .content .text.width-1 {
  max-width: 750px;
}

.microsite-text-block-article .content .text.width-2, .microsite-text-block .content .text.width-2 {
  max-width: 997px;
}

.microsite-text-block-article .content .text.width-3, .microsite-text-block .content .text.width-3 {
  max-width: 100%;
}

.microsite-text-block-article.centered .text, .microsite-text-block.centered .text {
  margin: 0 auto;
}

.microsite-text-block-article.centered .button-container, .microsite-text-block.centered .button-container {
  text-align: center;
}

.microsite-text-block-article.centered .columns, .microsite-text-block.centered .columns {
  justify-content: space-evenly;
  margin-top: 2rem;
}

.microsite-text-block-article .button-section, .microsite-text-block .button-section {
  justify-content: center;
  display: flex;
}

.microsite-text-block {
  background-color: #f9f9f8;
  padding: 40px 0 32px;
}

@media screen and (width >= 900px) {
  .microsite-text-block {
    background-image: url("../bg-dots-light.07c3b832.svg");
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 72px 0 64px;
  }
}

.microsite-text-block * {
  color: #000;
}

.microsite-text-block__inner {
  max-width: 640px;
}

.microsite-text-block .button-section {
  justify-content: center;
  display: flex;
}

.microsite-text-image-block {
  position: relative;
}

.microsite-text-image-block[id]:not([id=""]) {
  scroll-margin-top: 80px;
}

@media screen and (width >= 1113px) {
  .microsite-text-image-block[id]:not([id=""]) {
    scroll-margin-top: 120px;
  }
}

@media screen and (width >= 900px) {
  .microsite-text-image-block .container {
    justify-content: space-between;
    display: flex;
  }

  .microsite-text-image-block .container.image-left {
    flex-flow: row;
  }

  .microsite-text-image-block .container.image-right {
    flex-flow: row-reverse;
  }

  .microsite-text-image-block__image-container {
    flex: 0 0 50%;
  }
}

.microsite-text-image-block__image-container__inner {
  height: 320px;
  position: absolute;
  top: 0;
}

@media screen and (width <= 900px) {
  .microsite-text-image-block__image-container__inner {
    left: 0;
    right: 0;
  }
}

@media screen and (width >= 900px) {
  .microsite-text-image-block__image-container__inner {
    width: 50%;
    height: 560px;
    bottom: 0;
  }

  .microsite-text-image-block__image-container__inner.image-left {
    left: 0;
  }

  .microsite-text-image-block__image-container__inner.image-right {
    right: 0;
  }
}

.microsite-text-image-block__image-container__inner .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
}

.microsite-text-image-block__image-container__inner .overlay.black {
  filter: brightness(1.5) contrast(1.1);
  opacity: .8;
  background-color: #000000a6;
}

.microsite-text-image-block__image-container__inner .overlay.red {
  filter: brightness(1.25) contrast(1.1);
  opacity: .8;
  background-color: #3616188c;
}

.microsite-text-image-block__image-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.microsite-text-image-block__text-container {
  padding-top: 352px;
  padding-bottom: 48px;
}

@media screen and (width >= 900px) {
  .microsite-text-image-block__text-container {
    flex-flow: column;
    flex: 0 0 50%;
    justify-content: center;
    min-height: 560px;
    padding: 0 32px;
    display: flex;
  }

  .microsite-text-image-block__text-container.image-right {
    padding-left: 0;
  }

  .microsite-text-image-block__text-container.image-left {
    padding-right: 0;
  }
}

.microsite-text-image-block__text-container * {
  color: #000;
}

.microsite-text-image-block__text-container p:last-child {
  margin-bottom: 0;
}

.microsite-text-image-block__heading {
  text-transform: none;
  margin-bottom: 0;
  font-family: Exo, sans-serif;
  font-size: 1.625rem;
  font-weight: 500;
  line-height: 115%;
}

.microsite-text-image-block__heading.smaller-font {
  font-size: 1.8rem;
  font-weight: 400;
}

@media screen and (width >= 1113px) {
  .microsite-text-image-block__heading.smaller-font {
    font-size: 1.625rem;
  }
}

.microsite-text-image-block__heading.bigger-font {
  font-size: 1.8rem;
  font-weight: 400;
}

@media screen and (width >= 1113px) {
  .microsite-text-image-block__heading.bigger-font {
    font-size: 2.375rem;
    font-weight: 400;
  }
}

.microsite-text-image-block__link {
  margin-top: 1rem;
}

.microsite-contact-block {
  padding: 56px 0;
}

.microsite-contact-block[id]:not([id=""]) {
  scroll-margin-top: 80px;
}

@media screen and (width >= 1113px) {
  .microsite-contact-block[id]:not([id=""]) {
    scroll-margin-top: 120px;
  }
}

.microsite-contact-block h2 {
  margin-bottom: 2rem;
  font-size: 2.375rem;
  font-weight: 400;
}

.microsite-contact-block .sections {
  grid-template-columns: 1fr;
  gap: 36px;
  display: grid;
}

@media screen and (width >= 1100px) {
  .microsite-contact-block .sections {
    grid-template-columns: 1fr 1fr;
  }
}

.microsite-contact-block .sections .form-section form {
  grid-template-columns: 1fr 1fr;
  gap: 10px 23px;
  display: grid;
}

.microsite-contact-block .sections .form-section form .hs_email, .microsite-contact-block .sections .form-section form .hs_company, .microsite-contact-block .sections .form-section form .hs-fieldtype-select, .microsite-contact-block .sections .form-section form .legal-consent-container, .microsite-contact-block .sections .form-section form .hs_submit {
  grid-column: span 2;
}

.microsite-contact-block .sections .form-section form .hs-field-desc {
  margin-left: 2px;
}

.microsite-contact-block .sections .form-section form label {
  color: #2a312c;
  flex-direction: row;
  margin-bottom: .5rem;
  font-family: Arial;
  font-size: 16px;
  font-weight: 700;
  display: flex;
}

.microsite-contact-block .sections .form-section form label .hs-form-required {
  order: -1;
}

.microsite-contact-block .sections .form-section form .hs-input {
  background-color: #0000;
  border: 1px solid;
  width: 100%;
  height: 40px;
  font-family: Arial;
}

.microsite-contact-block .sections .form-section form .hs-input::placeholder {
  color: #606d63;
  font-family: Arial;
  font-size: 14px;
  font-weight: 400;
}

.microsite-contact-block .sections .form-section form .legal-consent-container .input ul {
  padding: 0;
  list-style-type: none;
}

.microsite-contact-block .sections .form-section form .legal-consent-container .input ul input {
  width: auto;
  height: 30px;
}

.microsite-contact-block .sections .form-section form .legal-consent-container .input ul span {
  margin-left: 10px;
}

.microsite-contact-block .sections .form-section form .hs-button {
  color: #fff;
  background-color: #d7323c;
  border: 1px solid #d7323c;
  height: auto;
  padding: 1.12rem 3.12rem;
  transition: color .25s, background-color .25s;
}

.microsite-contact-block .sections .form-section form .hs-button:hover, .microsite-contact-block .sections .form-section form .hs-button:focus-visible {
  color: #d7323c;
  background-color: #0000;
}

.microsite-contact-block .sections .form-section form .hs-error-msgs {
  padding: 0;
  list-style-type: none;
}

.microsite-contact-block .sections .contacts-section {
  grid-template-columns: repeat(1, 1fr);
  gap: 37px;
  display: grid;
}

@media screen and (width >= 600px) {
  .microsite-contact-block .sections .contacts-section {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (width >= 1100px) {
  .microsite-contact-block .sections .contacts-section {
    grid-template-columns: repeat(3, 1fr);
    margin-top: 34px;
  }
}

.microsite-contact-block .sections .contacts-section .employee {
  flex-direction: column;
  width: 100%;
  display: flex;
}

.microsite-contact-block .sections .contacts-section .employee__picture {
  width: 100%;
  overflow: hidden;
}

.microsite-contact-block .sections .contacts-section .employee__picture img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  display: block;
}

@media screen and (width <= 1099px) {
  .microsite-contact-block .sections .contacts-section .employee__picture img {
    height: 500px;
  }
}

@media screen and (width >= 1100px) {
  .microsite-contact-block .sections .contacts-section .employee__picture img {
    min-width: 188px;
    max-height: 228px;
  }
}

.microsite-contact-block .sections .contacts-section .employee__name {
  color: #d7323c;
  margin-top: 15px;
  margin-bottom: 0;
  font-size: .875rem;
  font-weight: 700;
}

.microsite-contact-block .sections .contacts-section .employee__role {
  margin: 15px 0 0;
  font-size: .875rem;
}

.microsite-contact-block .sections .contacts-section .employee__phone, .microsite-contact-block .sections .contacts-section .employee__mail, .microsite-contact-block .sections .contacts-section .employee__linkedin {
  color: #465149;
  font-size: .875rem;
  transition: all .2s ease-in-out;
}

.microsite-contact-block .sections .contacts-section .employee__phone:hover, .microsite-contact-block .sections .contacts-section .employee__mail:hover, .microsite-contact-block .sections .contacts-section .employee__linkedin:hover {
  color: #d7323c;
  text-decoration: underline;
}

.microsite-contact-block .sections .contacts-section .employee__linkedin {
  align-items: center;
  display: flex;
}

.microsite-contact-block .sections .contacts-section .employee__linkedin svg {
  width: 24px;
  height: 24px;
}

.microsite-contact-block .sections .contacts-section .employee__linkedin:hover {
  filter: grayscale();
}

.microsite-marquee-block {
  padding: 32px 0;
}

@media screen and (width >= 900px) {
  .microsite-marquee-block {
    padding: 56px 0;
  }
}

.microsite-marquee-block .container h2 {
  text-align: center;
  text-transform: none;
  font-family: Exo, sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 120%;
}

@media screen and (width >= 900px) {
  .microsite-marquee-block .container h2 {
    font-size: 2rem;
  }
}

@media screen and (width >= 1113px) {
  .microsite-marquee-block .container h2 {
    font-size: 2.375rem;
  }
}

@media screen and (width >= 600px) {
  .microsite-marquee-block .container h2 {
    margin-bottom: 3rem;
  }
}

.microsite-marquee-block .splide__slide {
  height: 45px;
  margin: 0 40px 0 0 !important;
}

@media screen and (width >= 600px) {
  .microsite-marquee-block .splide__slide {
    height: 62px;
    margin: 4px 80px 4px 0 !important;
  }
}

.microsite-marquee-block .splide__slide img {
  height: 45px;
  width: auto !important;
}

@media screen and (width >= 600px) {
  .microsite-marquee-block .splide__slide img {
    height: 62px;
  }
}

.microsite-marquee-block .splide__slide a {
  transition: transform .8s;
}

.microsite-marquee-block .splide__slide a:focus-visible {
  outline-offset: 1px;
  outline: 2px solid #d7323c;
}

.microsite-marquee-block .splide__slide a:hover img {
  transform: scale(.9);
}

.microsite-article__hero {
  position: relative;
}

.microsite-article__hero .container {
  flex-flow: column-reverse;
  justify-content: space-between;
  display: flex;
}

@media screen and (width >= 900px) {
  .microsite-article__hero .container {
    flex-flow: row;
  }
}

.microsite-article__hero__text-container {
  padding-top: 64px;
  padding-bottom: 48px;
}

@media screen and (width >= 900px) {
  .microsite-article__hero__text-container {
    flex-flow: column;
    justify-content: center;
    height: 560px;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
  }
}

@media screen and (width >= 1113px) {
  .microsite-article__hero__text-container {
    height: 640px;
  }
}

.microsite-article__hero__preamble {
  color: #000;
  max-width: 520px;
  margin: 0;
}

@media screen and (width >= 900px) {
  .microsite-article__hero__image-container {
    flex: 0 0 50%;
  }
}

.microsite-article__hero__image-container__inner {
  z-index: -1;
  position: absolute;
  top: 0;
  overflow: hidden;
}

@media screen and (width <= 900px) {
  .microsite-article__hero__image-container__inner {
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@media screen and (width >= 900px) {
  .microsite-article__hero__image-container__inner {
    width: 57vw;
    height: 560px;
    bottom: 0;
    right: 0;
  }
}

@media screen and (width >= 1113px) {
  .microsite-article__hero__image-container__inner {
    height: 640px;
  }
}

.microsite-article__hero__image-container img {
  object-fit: cover;
  outline-offset: -800px;
  outline: 800px solid #fff9;
  width: 100%;
  height: 100%;
}

@media screen and (width >= 900px) {
  .microsite-article__hero__image-container img {
    outline-color: #ffffff40;
  }
}

.microsite-article__content {
  margin-top: 56px;
}

@media screen and (width >= 1113px) {
  .microsite-article__content {
    margin-top: 96px;
  }
}

.microsite-article__related {
  margin-top: 104px;
}

@media screen and (width >= 900px) {
  .microsite-article__related {
    margin-top: 168px;
  }
}

.microsite-service__hero {
  position: relative;
}

.microsite-service__hero .container {
  flex-flow: column-reverse;
  justify-content: space-between;
  display: flex;
}

@media screen and (width >= 900px) {
  .microsite-service__hero .container {
    flex-flow: row;
  }
}

.microsite-service__hero__text-container {
  padding-top: 64px;
  padding-bottom: 48px;
}

@media screen and (width >= 900px) {
  .microsite-service__hero__text-container {
    flex-flow: column;
    justify-content: center;
    height: 560px;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
  }
}

@media screen and (width >= 1113px) {
  .microsite-service__hero__text-container {
    height: 640px;
  }
}

.microsite-service__hero__preamble {
  color: #000;
  max-width: 520px;
  margin: 0;
}

@media screen and (width >= 900px) {
  .microsite-service__hero__image-container {
    flex: 0 0 50%;
  }
}

.microsite-service__hero__image-container__inner {
  z-index: -1;
  position: absolute;
  top: 0;
  overflow: hidden;
}

@media screen and (width <= 900px) {
  .microsite-service__hero__image-container__inner {
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@media screen and (width >= 900px) {
  .microsite-service__hero__image-container__inner {
    width: 57vw;
    height: 560px;
    bottom: 0;
    right: 0;
  }
}

@media screen and (width >= 1113px) {
  .microsite-service__hero__image-container__inner {
    height: 640px;
  }
}

.microsite-service__hero__image-container img {
  object-fit: cover;
  outline-offset: -800px;
  outline: 800px solid #fff9;
  width: 100%;
  height: 100%;
}

@media screen and (width >= 900px) {
  .microsite-service__hero__image-container img {
    outline-color: #ffffff40;
  }
}

.microsite-service__content {
  margin-top: 56px;
}

@media screen and (width >= 1113px) {
  .microsite-service__content {
    margin-top: 96px;
  }
}

.microsite-service__related {
  margin-top: 104px;
}

@media screen and (width >= 900px) {
  .microsite-service__related {
    margin-top: 168px;
  }
}

.microsite-flow {
  padding-top: 16px;
}

@media screen and (width >= 900px) {
  .microsite-flow {
    padding-top: 32px;
  }
}

.microsite-flow__highlight {
  padding-top: 24px;
  padding-bottom: 8px;
}

.microsite-flow__highlight:focus-visible {
  outline-offset: 1px;
  outline: 2px solid #d7323c;
}

@media screen and (width >= 900px) {
  .microsite-flow__highlight {
    padding-top: 32px;
  }
}

.microsite-flow__highlight:hover img, .microsite-flow__highlight:focus-visible img {
  transform: scale(1.1);
}

.microsite-flow__highlight:hover .text-link, .microsite-flow__highlight:focus-visible .text-link {
  text-underline-offset: .75rem;
  color: #d7323c !important;
}

.microsite-flow__highlight__inner {
  flex-flow: column;
  display: flex;
}

@media screen and (width >= 900px) {
  .microsite-flow__highlight__inner {
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
  }
}

.microsite-flow__highlight__image-container {
  aspect-ratio: 2;
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
  overflow: hidden;
}

@media screen and (width >= 900px) {
  .microsite-flow__highlight__image-container {
    aspect-ratio: 4 / 3;
    flex-shrink: 0;
    width: 50%;
    margin-bottom: 0;
  }
}

.microsite-flow__highlight__image-container img {
  object-fit: cover;
  transform-origin: center;
  width: 100%;
  height: 100%;
  transition: transform .25s ease-in;
}

.microsite-flow__highlight__text-container * {
  color: #000;
}

@media screen and (width >= 900px) {
  .microsite-flow__highlight__text-container {
    width: 50%;
    padding: 32px;
  }

  .microsite-flow__highlight__text-container * {
    max-width: 520px;
  }
}

.microsite-flow__highlight__pre-heading {
  text-transform: uppercase;
  margin-bottom: .25rem;
}

@media screen and (width >= 900px) {
  .microsite-flow__highlight__pre-heading {
    margin-top: 0;
  }
}

.microsite-playbook-navigation {
  padding: 64px 0;
}

@media screen and (width >= 900px) {
  .microsite-playbook-navigation {
    padding: 112px 0;
  }
}

.microsite-playbook-navigation .container {
  flex-flow: column;
  justify-content: space-between;
  display: flex;
}

@media screen and (width >= 900px) {
  .microsite-playbook-navigation .container {
    flex-flow: row;
    align-items: center;
  }

  .microsite-playbook-navigation__left {
    width: 50%;
    padding-right: 40px;
  }
}

.microsite-playbook-navigation__links__list {
  flex-flow: wrap;
  justify-content: flex-start;
  margin: 0;
  padding: 24px 0 0;
  list-style: none;
  display: flex;
}

.microsite-playbook-navigation__links__list__item a {
  border: 1px solid #e7e7e7;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
  margin: 0 16px 16px 0;
  transition: border-color .25s;
  display: flex;
}

.microsite-playbook-navigation__links__list__item a:focus-visible {
  outline-offset: 1px;
  outline: 2px solid #d7323c;
}

@media screen and (width >= 900px) {
  .microsite-playbook-navigation__links__list__item a {
    width: 80px;
    height: 80px;
    margin-right: 32px;
  }
}

.microsite-playbook-navigation__links__list__item a svg {
  max-width: 32px;
}

.microsite-playbook-navigation__links__list__item a svg * {
  transition: all .25s;
}

.microsite-playbook-navigation__links__list__item a span {
  color: #000;
  font-family: Arial;
  font-size: .875rem;
  font-weight: 400;
  transition: color .25s;
}

.microsite-playbook-navigation__links__list__item a:hover, .microsite-playbook-navigation__links__list__item a:focus-visible, .microsite-playbook-navigation__links__list__item a[aria-current="page"] {
  border-color: #d7323c;
}

.microsite-playbook-navigation__links__list__item a:hover [stroke]:not([stroke="none"]), .microsite-playbook-navigation__links__list__item a:focus-visible [stroke]:not([stroke="none"]), .microsite-playbook-navigation__links__list__item a[aria-current="page"] [stroke]:not([stroke="none"]) {
  stroke: #d7323c;
}

.microsite-playbook-navigation__links__list__item a:hover [fill]:not([fill="none"]), .microsite-playbook-navigation__links__list__item a:focus-visible [fill]:not([fill="none"]), .microsite-playbook-navigation__links__list__item a[aria-current="page"] [fill]:not([fill="none"]) {
  fill: #d7323c;
}

.microsite-playbook-navigation__links__list__item a:hover span, .microsite-playbook-navigation__links__list__item a:focus-visible span, .microsite-playbook-navigation__links__list__item a[aria-current="page"] span {
  color: #d7323c;
}

@media screen and (width >= 900px) {
  .microsite-playbook-navigation__right {
    width: 50%;
  }
}

.microsite-playbook-navigation__right .body {
  color: #000;
}

.microsite-reference-intro__text .quote {
  font-style: italic;
}

@media screen and (width >= 900px) {
  .microsite-reference-intro__text .reference {
    margin: 2rem 0;
  }
}

body.micro-site [x-cloak] {
  display: none !important;
}

body.micro-site.cybersec ::selection {
  color: #fff;
  background: #d7323c;
}
/*# sourceMappingURL=main.css.map */
